import { InputNumber } from "primereact/inputnumber";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

const PriceEstimationForm = ({ data, title = "Estimasi Biaya Kirim (otomatis dikalkulasi)" }) => {
  const { control, reset } = useForm();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);
  return (
    <div className="non-input p-fluid grid formgrid col-12 p-0 m-0">
      <div className="field col-12 md:col-12 mt-4">
        <p className="font-bold">{title}</p>
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="fee_cod">Biaya COD : </label>
        <Controller control={control} name="fee_cod" defaultValue={0} render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="tax_percentage">Pajak : </label>
        <Controller control={control} name="tax_percentage" defaultValue={0} render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="fee_tax">Nilai Pajak : </label>
        <Controller defaultValue={0} control={control} name="fee_tax" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="discount_percentage">Persentasi Diskon : </label>
        <Controller defaultValue={0} control={control} name="discount_percentage" render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" disabled />} />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="fee_discount">Nilai Diskon : </label>
        <Controller defaultValue={0} control={control} name="fee_discount" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="shipping_cost">Biaya Pengiriman : </label>
        <Controller defaultValue={0} control={control} name="shipping_cost" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="total_price">Total Biaya : </label>
        <Controller defaultValue={0} control={control} name="total_price" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
      </div>
    </div>
  );
};

export default PriceEstimationForm;
