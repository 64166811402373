// hooks
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

// components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Link, useNavigate } from "react-router-dom";

// utils
import classnames from "classnames";
import toast from "react-hot-toast";
import UserApi from "../api/UserApi";
import Cookies from "js-cookie";
import Decode from "jwt-decode";

import Logo from "../assets/images/logo_with_text.png";
import useUserStore from "../stores/userStore";
import PagesApi from "../api/PagesApi";

export default function Login() {
  const navigate = useNavigate();
  const userStore = useUserStore();

  const { isLoading, mutate: login } = useMutation(async (data) => UserApi.Login(data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        let error = response.data.message;
        toast.error(error, { duration: 4000 });
        return;
      }

      let { token, user } = response.data.data;
      let decodedToken = Decode(token);
      Cookies.set("token", token, decodedToken.exp);

      // set user to store
      if (user.role_id.permissions) {
        permissionHandler(user, user.role_id.permissions);
      } else {
        userStore.resetUser();
        toast.error("You dont have any permission");
        Cookies.remove("token");
        navigate("/login");
      }
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    login(data);
  };

  const permissionHandler = async (user, permission) => {
    const response = await PagesApi.GetMenu();
    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    const menu = response.data.data;
    const user_permissions = permission;

    // user dont have any permissions
    if (!user_permissions.length) {
      userStore.resetUser();
      localStorage.removeItem("token");
      return;
    }

    // user has permissions
    let user_route_permissions = [];
    let user_allowed_menu = [];

    // find and store route with actions view true
    user_permissions.map((data) => data.actions.view && user_route_permissions.push(data?.page_id?.route));

    // create new menu that related to user permissions
    for (let index = 0; index < menu.length; index++) {
      user_allowed_menu.push({ label: menu[index].label, items: [] });
      menu[index].items.map((item) => {
        let isExist = user_route_permissions.includes(item.to);
        return isExist && user_allowed_menu[index].items.push(item);
      });
    }

    // remove empty group menu
    let final_user_allowed_menu = [];
    user_allowed_menu.map((data) => data.items.length && final_user_allowed_menu.push(data));

    // save user data in store and re derect
    userStore.setRouteMenu(final_user_allowed_menu);
    userStore.setRoutePermissions(permission);
    userStore.setUser(user);

    // save user address if user have address
    if (user.addresses.length) {
      userStore.setUserAddresses(user.addresses);
    }

    toast.success("Login Berhasil");
    navigate("/dashboard");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex justify-content-center align-content-center w-full min-h-screen">
      <div className="flex align-items-center justify-content-center w-full">
        <div className="surface-card  shadow-2 col-11 lg:w-3 p-2">
          <div className="text-center mt-4 mb-2">
            <img width="140" src={Logo} alt="logo" />
          </div>
          <div style={{ margin: 0 }} className="p-fluid grid formgrid col-12 mt-4">
            <div className="field col-12 md:col-12">
              <label htmlFor="email" className="block text-900 font-medium ">
                Email
              </label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="email"
                render={({ field }) => <InputText id="email" autoComplete="username" placeholder="input email" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={classnames("w-full", { "p-invalid": errors.email })} />}
              />
              {errors.email && (
                <small id="name" className="p-error block pt-1">
                  Masukkan E-mail anda
                </small>
              )}
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="email" className="block text-900 font-medium mb-2">
                Password
              </label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="password"
                render={({ field }) => <Password value={field.value} autoComplete="current-password" placeholder="Masukkan password" onChange={(e) => field.onChange(e)} toggleMask feedback={false} className="w-full" inputClassName={classnames("w-full", { "p-invalid": errors.password })} />}
              />
              {errors.password && (
                <small id="name" className="p-error block pt-1">
                  Masukkan password
                </small>
              )}
            </div>

            <div className="field col-12 md:col-12">
              <Button loading={isLoading} label="Masuk" className="w-full mt-2 p-button-success" />
              <div className="w-full mt-3">
                <div className="">
                  <p className="text-center text-gray-800">
                    Belum punya akun ?{" "}
                    <Link to="/register">
                      <span className="font-medium underline text-gray-800">Daftar Sekarang</span>
                    </Link>{" "}
                  </p>
                  <p className="text-center">
                    <Button onClick={() => navigate("/forget-password")} className="p-button-secondary p-button-text" label="Lupa Password" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
