import { Calendar } from "primereact/calendar";
import classNames from "classnames";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import Api from "../../../api/Api";
import CreateAddressModal from "../../user/CreateAddressModal";

const SenderInformation = ({ watch, control, formState, getValues, reset, setOriginCode, setCourierName }) => {
  const today_date = new Date();
  const token = Cookies.get("token");
  const user = jwtDecode(token);
  const selectedSenderAddress = watch("address", {});

  // state
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [address, setAddress] = useState([]);
  const [service, setService] = useState([]);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [disableAddress, setDisableAddress] = useState(true);

  // useQuery
  const { loading, data: couriers } = useQuery("couriers", () => getCouriers(), { initialData: [], refetchOnWindowFocus: false });
  const { isLoading: loadingGetAddress, mutate: getUserAddress } = useMutation(async (data) => await Api().post("/user/addresses", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }

      let available_address = response.data.data.filter((address) => address.active_status);
      setAddress(available_address);
    },
  });
  const { mutate: getService, isLoading: isLoadingGetService } = useMutation(async (data) => await Api().post("/service-type/services", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan service");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }
      setService(response.data.data);
    },
  });
  const { mutate: getOriginCode } = useMutation(async (data) => await Api().post("/address/code", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }

      if (response.data.status !== 200) {
        const current_value = getValues();
        reset({ ...current_value, address: {} });
        return toast.error(response.data.message);
      }

      if (setOriginCode) {
        setOriginCode(response.data.data);
      }
    },
  });

  // functions
  const getCouriers = async () => {
    try {
      const response = await Api().get("/courier");

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      return response.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getUserAddress({
      user_id: user._id,
    });
  }, [getUserAddress, user._id]);

  useEffect(() => {
    if (selectedCourier) {
      getService({ courier_id: selectedCourier });
    }
  }, [getService, selectedCourier]);

  useEffect(() => {
    if (Object.keys(selectedSenderAddress).length) {
      const current_value = getValues();
      getOriginCode({
        courier_id: current_value.courier_id,
        address: current_value.address,
        type: "origin",
      });
    }
  }, [selectedSenderAddress, getValues, getOriginCode]);

  // child component
  const EmptyMessage = () => {
    return (
      <div className="flex">
        <Button onClick={() => setShowModalAddress(true)} className="p-button-success" label="tambah alamat+" />
      </div>
    );
  };

  return (
    <>
      <div className="field col-12 ">
        <label htmlFor="choose-kurir">Pilih Kurir :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="courier_id"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              disabled={loading && !couriers.length}
              optionLabel="name"
              optionValue="_id"
              value={field.value}
              onBlur={field.onBlur}
              options={couriers}
              className={classNames({
                "p-invalid": formState.errors.courier_id,
              })}
              onChange={(e) => {
                field.onChange(e.value);
                let courier = couriers.find((courier) => courier._id === e.value);
                setCourierName(courier.name);
                setSelectedCourier(e.value);
                reset({ ...getValues(), service_type: null, receiver_info: { ...getValues()?.receiver_info, full_address: null } });
              }}
              placeholder="Pilih Kurir"
            />
          )}
        />
        {formState.errors.courier_id && (
          <small id="choose-kurir" className="p-error block pt-1">
            Anda belum memilih Kurir
          </small>
        )}
      </div>
      <div className="field col-12 ">
        <label htmlFor="service_type">Pilih Jenis Layanan :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="service_type"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              disabled={!service.length}
              optionLabel="display_name"
              optionValue="name"
              value={field.value}
              onBlur={field.onBlur}
              options={service}
              className={classNames({
                "p-invalid": formState.errors.service_type,
              })}
              onChange={(e) => {
                field.onChange(e);
                setDisableAddress(false);
              }}
              placeholder={isLoadingGetService ? "Loading..." : "Pilih Jenis Layanan"}
            />
          )}
        />
        {formState.errors.service_type && (
          <small id="choose-kurir" className="p-error block pt-1">
            Anda belum memilih layanan
          </small>
        )}
      </div>
      <div className="field col-12 ">
        <label htmlFor="address">Pilih Alamat :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="address"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              disabled={loadingGetAddress || disableAddress}
              optionLabel="name"
              value={field.value}
              onBlur={field.onBlur}
              options={address}
              emptyMessage={EmptyMessage}
              className={classNames({
                "p-invalid": formState.errors.address,
              })}
              onChange={(e) => {
                field.onChange(e);
              }}
              placeholder="Pilih Alamat"
            />
          )}
        />
        {formState.errors.address && (
          <small id="choose-kurir" className="p-error block pt-1">
            Anda belum memilih alamat
          </small>
        )}
      </div>
      {/* address preview */}
      {Object.keys(selectedSenderAddress).length ? (
        <div className="field col-12">
          <div className="p-4 user-destination-wrapper">
            <h6 className="font-bold">
              {selectedSenderAddress.name}, {selectedSenderAddress.phone_number}
            </h6>
            <p>
              {selectedSenderAddress.address} {selectedSenderAddress.province} {selectedSenderAddress.district} {selectedSenderAddress.city} {selectedSenderAddress.postal_code}
            </p>
          </div>
        </div>
      ) : null}
      <div className="field col-12 ">
        <label htmlFor="last_name">Pilih Waktu Pickup :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={today_date}
          name="pickup_date"
          render={({ field }) => (
            <Calendar
              className={classNames({
                "p-invalid": formState.errors.pickup_date,
              })}
              ref={field.ref}
              id="pickup_date"
              value={field.value}
              showIcon
              showTime
              minDate={today_date}
              onChange={(e) => field.onChange(e)}
              placeholder="Masukkan waktu pickup"
            />
          )}
        />
        {formState.errors.pickup_date && (
          <small id="pickup_date" className="p-error block pt-1">
            Silahkan pilih waktu pickup
          </small>
        )}
      </div>

      <CreateAddressModal
        show={showModalAddress}
        setShow={setShowModalAddress}
        refetch={() =>
          getUserAddress({
            user_id: user._id,
          })
        }
      />
    </>
  );
};

export default SenderInformation;
