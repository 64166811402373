// hooks
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

// components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Link, useNavigate } from "react-router-dom";

// utils
import classnames from "classnames";
import toast from "react-hot-toast";
import Logo from "../assets/images/logo_with_text.png";
import Api from "../api/Api";

export default function ForgetPassword() {
  const [disableButton, setDisableButton] = useState(false);
  const [time, setTime] = useState(30);
  const navigate = useNavigate();
  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const [isNextStep, setIsNextStep] = useState(false);
  const { isLoading, mutate: sendOTP } = useMutation(async (data) => Api().post("user/forgot-password", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        let error = response.data.message;
        toast.error(error, { duration: 4000 });
        return;
      }
      toast.success("berhasil Mengirim otp ke email anda");
      navigate({ pathname: "/forget-password", email: `?email=${getValues().email}` });
      setDisableButton(true);
      setIsNextStep(true);
    },
  });
  const { isLoading: loadingResetPassword, mutate: resetPassword } = useMutation(async (data) => Api().post("/user/reset-password", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        let error = response.data.message;
        toast.error(error, { duration: 4000 });
        return;
      }
      toast.success("Password Berhasil Di Reset");
      navigate("/login");
    },
  });

  const timeOutCallback = useCallback(() => setTime((currTimer) => currTimer - 1), []);

  useEffect(() => {
    if (disableButton && time > 0) {
      setTimeout(timeOutCallback, 1000);
    } else {
      setTime(30);
      setDisableButton(false);
    }
  }, [time, disableButton]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data) => {
    if (!isNextStep) {
      sendOTP(data);
    } else {
      // for reset password
      resetPassword(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex justify-content-center align-content-center w-full min-h-screen">
      <div className="flex align-items-center justify-content-center w-full">
        <div className="surface-card  shadow-2 col-11 lg:w-3 p-2">
          <div className="text-center mt-4 mb-2">
            <img width="140" src={Logo} alt="logo" />
          </div>
          <div style={{ margin: 0 }} className="p-fluid grid formgrid col-12 mt-4">
            {isNextStep && (
              <div className="field col-12 md:col-12 flex justify-content-center align-items-center">
                <p style={{ margin: 0 }} className="pr-2">
                  Belum Dapat Kode ?
                </p>
                <p
                  onClick={() => {
                    sendOTP({ email: getValues().email });
                  }}
                  disabled={disableButton}
                  className={classnames({
                    "cursor-pointer": time > 0 && disableButton,
                  })}
                >
                  {time > 0 && disableButton ? `${time} detik` : "Kirim ulang"}
                </p>
              </div>
            )}
            <div className="field col-12 md:col-12">
              <label htmlFor="email" className="block text-900 font-medium ">
                Email
              </label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="email"
                render={({ field }) => <InputText id="email" autoComplete="username" placeholder="input email" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={classnames("w-full", { "p-invalid": errors.email })} />}
              />
              {errors.email && (
                <small id="name" className="p-error block pt-1">
                  Masukkan E-mail anda
                </small>
              )}
            </div>
            {isNextStep && (
              <div className="field col-12 md:col-12">
                <label htmlFor="email" className="block text-900 font-medium mb-2">
                  Password
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="password"
                  render={({ field }) => <Password value={field.value} autoComplete="current-password" placeholder="Masukkan password" onChange={(e) => field.onChange(e)} toggleMask feedback={false} className="w-full" inputClassName={classnames("w-full", { "p-invalid": errors.password })} />}
                />
                {errors.password && (
                  <small id="name" className="p-error block pt-1">
                    Masukkan password
                  </small>
                )}
              </div>
            )}

            {isNextStep && (
              <div className="field col-12 md:col-12">
                <label htmlFor="confirm_password" className="block text-900 font-medium mb-2">
                  Konfirmasi Password
                </label>
                <Controller
                  rules={{ validate: (value) => value === password.current || "Pssword tidak sama", required: true }}
                  control={control}
                  name="confirm_password"
                  render={({ field }) => (
                    <Password value={field.value} autoComplete="current-password" placeholder="Masukkan konfirmasi password" onChange={(e) => field.onChange(e)} toggleMask feedback={false} className="w-full" inputClassName={classnames("w-full", { "p-invalid": errors.confirm_password })} />
                  )}
                />
                {errors.confirm_password && (
                  <small id="confirm_password" className="p-error block pt-1">
                    {errors.confirm_password.message}
                  </small>
                )}
              </div>
            )}

            {isNextStep && (
              <div className="field col-12 md:col-12">
                <label htmlFor="token" className="block text-900 font-medium ">
                  Kode OTP
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="token"
                  render={({ field }) => (
                    <InputText id="token" autoComplete="username" placeholder="Masukkan kode otp yang anda terima di email" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={classnames("w-full", { "p-invalid": errors.token })} />
                  )}
                />
                {errors.token && (
                  <small id="name" className="p-error block pt-1">
                    Masukkan kode otp yang anda terima di email
                  </small>
                )}
              </div>
            )}

            <div className="field col-12 md:col-12">
              {!isNextStep ? <Button loading={isLoading} label="Reset Password" className="w-full mt-2 p-button-success" /> : <Button loading={loadingResetPassword} label="Reset Password" className="w-full mt-2 p-button-success" />}
              <div className="w-full mt-3">
                <div className="">
                  <p className="text-center">
                    <Link to="/login">
                      <Button className="p-button-secondary p-button-text" label="Kembali Ke Login"></Button>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
