import classNames from "classnames";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import Api from "../../api/Api";

const UpdateAddressModal = ({ show, setShow, refetch, value }) => {
  const token = Cookies.get("token");
  const user = jwtDecode(token);

  const { handleSubmit, control, formState, reset, getValues } = useForm();

  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [distric, setDistric] = useState([]);
  const [urban, setUrban] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/user/edit-address", data), {
    onSettled: (response, error) => {
      if (error) {
        toast.success("Gagal Update Alamat", { duration: 4000 });
      }

      if (response.data.status === 200) {
        refetch();
        setShow(false);
        toast.success("Berhasil Update Alamat", { duration: 4000 });
      } else {
        refetch();
        setShow(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const getAddressOption = async (payload, type) => {
    try {
      const response = await Api().post("/address", payload);

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      // success
      const data = response.data.data;
      switch (type) {
        case "province":
          setProvince(data);
          break;
        case "city":
          setCity(data);
          break;
        case "distric":
          setDistric(data);
          break;
        case "urban":
          setUrban(data);
          break;
        case "postal_code":
          setPostalCode(data);
          break;
        default:
          toast.error("Address type not found");
          break;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const update = (data) => {
    let payload = {
      user_id: user._id,
      address_id: data._id,
      ...data,
    };

    updateMutate(payload);
  };

  useEffect(() => {
    const editHandler = async (data) => {
      // get data
      await getAddressOption(
        {
          province: data.province,
        },
        "city"
      );
      await getAddressOption(
        {
          city: data.city,
        },
        "distric"
      );
      await getAddressOption(
        {
          district: data.district,
        },
        "urban"
      );
      await getAddressOption(
        {
          urban: data.urban,
          district: data.district,
        },
        "postal_code"
      );

      setLoading(false);
      reset({ ...data });
    };

    if (Object.keys(value).length) {
      editHandler(value);
    }
  }, [value, reset, setShow]);

  useEffect(() => {
    getAddressOption(
      {
        all_province: true,
      },
      "province"
    );
  }, []);

  return (
    <Dialog visible={show} header="Ubah Alamat" modal className="modal-container p-fluid" footer onHide={() => setShow(false)}>
      <form className="mt-2" onSubmit={handleSubmit(update)}>
        <div className="field">
          <label htmlFor="address">Nama Toko : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="name"
            render={({ field }) => <InputText disabled={isLoading} placeholder="Masukkan nama toko" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors.name && "p-invalid"} />}
          />
          {formState.errors.name && (
            <small id="address" className="p-error block pt-1">
              Masukkan nama toko
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="email">Email : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="email"
            render={({ field }) => <InputText disabled={isLoading} placeholder="Masukkan email" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="email" type="text" className={formState.errors.email && "p-invalid"} />}
          />
          {formState.errors.email && (
            <small id="email" className="p-error block pt-1">
              Masukkan email
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="phone_number">No Hp : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            id="phone_number"
            name="phone_number"
            render={({ field }) => (
              <InputText
                placeholder="Masukkan no telp"
                value={field.value}
                onBlur={field.onBlur}
                disabled={isLoading}
                ref={field.ref}
                onChange={(e) => field.onChange(e)}
                id="phone_number"
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className={formState.errors.phone_number && "p-invalid"}
              />
            )}
          />
          {formState.errors.phone_number && (
            <small id="phone_number" className="p-error block pt-1">
              Masukkan no telp
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">Pilih Provinsi :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="province"
            render={({ field }) => (
              <Dropdown
                ref={field.ref}
                disabled={!province.length || isLoading}
                value={field.value}
                onBlur={field.onBlur}
                options={province}
                className={classNames({
                  "p-invalid": formState.errors.province,
                })}
                onChange={(e) => {
                  field.onChange(e);
                  getAddressOption(
                    {
                      province: e.target.value,
                    },
                    "city"
                  );
                  reset({ ...getValues(), city: null, district: null, urban: null, postal_code: null });
                }}
                placeholder="Pilih Provinsi"
              />
            )}
          />
          {formState.errors.province && (
            <small id="choose-kurir" className="p-error block pt-1">
              Anda belum memilih Provinsi
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">Pilih Kota :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="city"
            render={({ field }) => (
              <Dropdown
                ref={field.ref}
                disabled={!city.length || isLoading}
                value={field.value}
                onBlur={field.onBlur}
                options={city}
                className={classNames({
                  "p-invalid": formState.errors.city,
                })}
                onChange={(e) => {
                  field.onChange(e);
                  getAddressOption(
                    {
                      city: e.target.value,
                    },
                    "distric"
                  );
                  reset({ ...getValues(), district: null, urban: null, postal_code: null });
                }}
                placeholder="Pilih Kota"
              />
            )}
          />
          {formState.errors.city && (
            <small id="choose-kurir" className="p-error block pt-1">
              Anda belum memilih Kota
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">Pilih Kecamatan :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="district"
            render={({ field }) => (
              <Dropdown
                ref={field.ref}
                disabled={!distric.length || isLoading}
                value={field.value}
                onBlur={field.onBlur}
                options={distric}
                className={classNames({
                  "p-invalid": formState.errors.district,
                })}
                onChange={(e) => {
                  field.onChange(e);
                  getAddressOption(
                    {
                      district: e.target.value,
                    },
                    "urban"
                  );
                  reset({ ...getValues(), urban: null, postal_code: null });
                }}
                placeholder="Pilih Kecamatan"
              />
            )}
          />
          {formState.errors.district && (
            <small id="choose-kurir" className="p-error block pt-1">
              Anda belum memilih Kecamatan
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">Pilih Kelurahan :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="urban"
            render={({ field }) => (
              <Dropdown
                ref={field.ref}
                disabled={!urban.length || isLoading}
                value={field.value}
                onBlur={field.onBlur}
                options={urban}
                className={classNames({
                  "p-invalid": formState.errors.urban,
                })}
                onChange={(e) => {
                  field.onChange(e);
                  getAddressOption(
                    {
                      district: getValues().district,
                      urban: e.target.value,
                    },
                    "postal_code"
                  );
                  reset({ ...getValues(), postal_code: null });
                }}
                placeholder="Pilih Kelurahan"
              />
            )}
          />
          {formState.errors.urban && (
            <small id="choose-kurir" className="p-error block pt-1">
              Anda belum memilih Kelurahan
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">Pilih Kode Pos :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="postal_code"
            render={({ field }) => (
              <Dropdown
                ref={field.ref}
                disabled={!postalCode.length}
                value={field.value}
                onBlur={field.onBlur}
                options={postalCode}
                className={classNames({
                  "p-invalid": formState.errors.postal_code,
                })}
                onChange={(e) => {
                  field.onChange(e);
                }}
                placeholder="Pilih Kelurahan"
              />
            )}
          />
          {formState.errors.postal_code && (
            <small id="choose-kurir" className="p-error block pt-1">
              Anda belum memilih kodepos
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="address">Alamat Lengkap : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="address"
            defaultValue={""}
            render={({ field }) => <InputTextarea disabled={isLoading} value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={formState.errors.address && "p-invalid"} placeholder="Masukkan alamat" autoResize rows="3" cols="20" />}
          />
          {formState.errors.address && (
            <small id="choose-kurir" className="p-error block pt-1">
              Masukkan alamat lengkap
            </small>
          )}
        </div>
        <div className="field">
          <label className="block" htmlFor="active_status">
            Active status
          </label>
          <Controller control={control} name="active_status" render={({ field }) => <InputSwitch disabled={isLoading} onChange={(e) => field.onChange(e)} checked={field.value} />} />
        </div>
        <div className="flex justify-content-end">
          <div className="flex">
            <Button type="button" disabled={isLoading} onClick={() => setShow(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
            <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default UpdateAddressModal;
