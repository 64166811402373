import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import toast from "react-hot-toast";
import { AutoComplete } from "primereact/autocomplete";
import { InputTextarea } from "primereact/inputtextarea";
import { useMutation } from "react-query";
import Api from "../../../api/Api";
import { Button } from "primereact/button";

const ReciverInformation = ({ control, formState, getValues, setDestinationCode, reset, watch }) => {
  const [filterAddress, setFilterAddress] = useState([]);
  const receiver_info_value = watch("receiver_info.full_address");

  const { mutate: getAddress } = useMutation(async (data) => await Api().post("/address/autofill-code", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }

      setFilterAddress(response.data.data);
    },
  });
  const { mutate: getDestinationCode } = useMutation(async (data) => await Api().post("/address/code", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        const current_value = getValues();
        current_value.receiver_info.full_address = "";
        reset({ ...current_value });
        return toast.error(response.data.message);
      }
      setDestinationCode(response.data.data);
    },
  });

  const searchItems = (event) => {
    if (event.query.length > 3) {
      getAddress({
        courier_id: getValues().courier_id,
        text: event.query,
        type: "origin",
        for_address: true,
      });
    }
  };

  return (
    <>
      <div className="field col-12">
        <label htmlFor="receiver_info.receiver_name">Nama : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="receiver_info.receiver_name"
          render={({ field }) => <InputText placeholder="Masukkan nama penerima" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="receiver_info.receiver_name" type="text" className={formState.errors?.receiver_info?.receiver_name && "p-invalid"} />}
        />
        {formState.errors?.receiver_info?.receiver_name && (
          <small id="receiver_info.receiver_name" className="p-error block pt-1">
            Masukkan nama penerima
          </small>
        )}
      </div>
      <div className="field col-12">
        <label htmlFor="receiver_info.receiver_phone">No Hp : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="receiver_info.receiver_phone"
          render={({ field }) => (
            <InputText
              placeholder="Masukkan no hp"
              value={field.value}
              onBlur={field.onBlur}
              ref={field.ref}
              onChange={(e) => field.onChange(e)}
              id="receiver_info.receiver_phone"
              type="text"
              className={formState.errors?.receiver_info?.receiver_phone && "p-invalid"}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          )}
        />
        {formState.errors?.receiver_info?.receiver_phone && (
          <small id="receiver_info.receiver_phone" className="p-error block pt-1">
            Masukkan phone number
          </small>
        )}
      </div>
      <div className="field col-12">
        <label htmlFor="receiver_info.receiver_address">Alamat : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="receiver_info.receiver_address"
          defaultValue={""}
          render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={formState.errors?.receiver_info?.receiver_address && "p-invalid"} placeholder="Masukkan alamat lengkap" autoResize rows="3" cols="20" />}
        />
        {formState.errors?.receiver_info?.receiver_address && (
          <small id="receiver_info.receiver_address" className="p-error block pt-1">
            Masukkan nama alamat
          </small>
        )}
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="receiver_info.full_address">Kecamatan / Kota / Provinsi : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="receiver_info.full_address"
          defaultValue={""}
          render={({ field }) => (
            <div className="relative w-full">
              <AutoComplete
                forceSelection
                value={field.value}
                onBlur={field.onBlur}
                suggestions={filterAddress}
                completeMethod={searchItems}
                className={formState.errors?.receiver_info?.full_address && "p-invalid"}
                field="full_name"
                ref={field.ref}
                onChange={(e) => {
                  field.onChange(e);
                  if (e.value instanceof Object) {
                    getDestinationCode({
                      courier_id: getValues().courier_id,
                      address: e.value,
                      type: "destination",
                    });
                  }
                }}
                placeholder="Masukkan huruf minimal 4"
              />
              <div className="absolute top-0 text-center flex right-0">
                {typeof receiver_info_value === "object" && (
                  <Button
                    onClick={() =>
                      reset({
                        ...getValues(),
                        receiver_info: {
                          ...getValues().receiver_info,
                          full_address: null,
                        },
                      })
                    }
                    type="button"
                    className="w-full h-full p-button-danger"
                    label="X"
                    tooltip="Hapus"
                  />
                )}
              </div>
            </div>
          )}
        />
        {formState.errors?.receiver_info?.full_address && (
          <small id="receiver_info.full_address" className="p-error block pt-1">
            Anda belum memilih Kecamatan / Kota / Provinsi
          </small>
        )}
      </div>
    </>
  );
};

export default ReciverInformation;
