// hooks
import React, { useState } from "react";

// components
import { Toolbar } from "primereact/toolbar";
import { Steps } from "primereact/steps";
import SenderInformation from "../../components/requestpickup/form/SenderInformation";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PreviewDataMultiple } from "../../components/requestpickup/form/PreviewDataMultiple";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";

// utils
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Api from "../../api/Api";
import { useMutation } from "react-query";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import formatRupiah from "../../utils/formatRupiah";
import getClientIP4 from "../../utils/getClientIP4";

// data
const items = [
  {
    label: "Informasi Pengirim & Upload File",
  },
  {
    label: "Ringkasan & Buat Order",
  },
];

const CreateOrderMultiple = () => {
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [step, setStep] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [courierName, setCourierName] = useState("");
  const [successFileUrl, setSuccesFileUrl] = useState(null);
  const [failedFileUrl, setFailedFileUrl] = useState(null);
  const [modalSomeDataError, setModalSomeDataError] = useState(false);
  const [modalDataError, setModalDataError] = useState(false);
  const [errorFileMessage, setErrorFileMessage] = useState("");
  const [loadingGetIp, setLoadingGetIp] = useState(false);
  const { control, handleSubmit, formState, reset, trigger, getValues, watch } = useForm();

  // api call
  const { isLoading: loadingGetOrderTemplate, mutate: getOrderTemplate } = useMutation(async (data) => await Api().post("/order/template-file", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan template");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }

      downloadHandler(response.data.data, "Template-Order");
      setModalDataError(false);
    },
  });
  const { isLoading: loadingRequestPickup, mutate: requestPickUp } = useMutation(async (data) => await Api().post("/order/import", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal request pickup");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }

      reset({});
      setShowSuccessModal(true);
    },
  });
  const { isLoading: loadingCheckFile, mutate: checkFile } = useMutation(async (data) => await Api().post("/order/check-import-order", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal Upload File");
      }

      // data failed
      if (response.data.status !== 200) {
        toast.error(response.data.message, 5000);
        setFileName(null);
        setSuccesFileUrl(null);
        setFailedFileUrl(null);
        return;
      }

      if (!response.data.data.success_url && response.data.data.fail_url) {
        let msg = "Terjadi kesalahan pada data yang di-export. Silahkan download file untuk melihat data yang salah.";
        setErrorFileMessage(msg);
        setSuccesFileUrl(null);
        setFailedFileUrl(response.data.data.fail_url);
        setModalDataError(true);
        setFileName(null);
        return;
      }

      // only some data success
      if (response.data.data.success_url && response.data.data.fail_url) {
        setSuccesFileUrl(response.data.data.success_url);
        setFailedFileUrl(response.data.data.fail_url);
        setModalSomeDataError(true);
        return;
      }

      // success
      if (response.data.data.success) {
        setSuccesFileUrl(response.data.data.success_url);
        return;
      }
    },
  });
  const { isLoading: loadingSummaryData, mutate: getSummaryData } = useMutation(async (data) => await Api().post("/order/import-summary", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Mendapatkan Summary");
      }

      // data failed
      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      setPreviewData(response.data.data);
      setStep((prev) => prev + 1);
    },
  });

  const orderHandler = async (data) => {
    let payload = mappingData(data);
    payload.success_url = successFileUrl;

    try {
      setLoadingGetIp(true);
      const response = await getClientIP4();
      if (response.status !== 200) {
        throw new Error("failed");
      }
      setLoadingGetIp(false);
      requestPickUp({ ...payload, ip_address: response.data.IPv4 });
    } catch (error) {
      setLoadingGetIp(false);
      requestPickUp({ ...payload, ip_address: null });
    }
  };

  const mappingData = (data) => {
    if (!Object.keys(data).length) return {};

    let temp = { courier_id: null, body: {} };

    // mapping data
    temp.body = {};
    delete data.shipper_info;
    delete data.receiver_info;

    temp = {
      body: { ...temp.body, ...data },
    };

    temp.courier_id = temp.body.courier_id;
    delete temp.body.courier_id;

    temp.body = {
      ...temp.body,
      shipper_info: {},

      // default will change soon
      insurance: false,
      insurance_price: 0,
    };

    temp.body.shipper_info.shipper_phone = data.address.phone_number;
    temp.body.shipper_info.shipper_email = data.address.email;
    temp.body.shipper_info.shipper_name = data.address.name;
    temp.body.shipper_info.shipper_address = data.address.address;
    temp.body.shipper_info.shipper_province = data.address.province;
    temp.body.shipper_info.shipper_city = data.address.city;
    temp.body.shipper_info.shipper_district = data.address.district;
    temp.body.shipper_info.shipper_urban = data.address.urban;
    temp.body.shipper_info.shipper_zip = data.address.postal_code;
    temp.body.shipper_info.shipper_country = data.address.country ? data.address.country : null;

    delete temp.body.address;

    return temp;
  };

  const checkStepAndGetSummary = async () => {
    let isValid = false;

    switch (step) {
      case 0:
        isValid = await trigger(["courier_id", "service_type", "address", "pickup_date"]);
        break;
      default:
        break;
    }

    if (isValid) {
      let payload = mappingData(getValues());
      payload.success_url = successFileUrl;
      getSummaryData(payload);
      return;
    }
  };

  const downloadHandler = (url, fileName) => {
    let aTag = document.createElement("a");
    aTag.href = url;
    aTag.target = "_blank";
    aTag.download = fileName;
    aTag.click();
  };

  const handleFileUpload = (e) => {
    // reset state
    setFailedFileUrl(null);
    setSuccesFileUrl(null);

    // 2MB max
    if (e.target.files[0].size > 2000000) {
      toast.error("File tidak boleh lebih dari 2MB");
      return;
    }

    setFileName(e.target.files[0].name);

    const formData = new FormData();
    const current_values = getValues();

    formData.append("courier_id", current_values.courier_id);
    formData.append("file", e.target.files[0]);

    checkFile(formData);
  };

  const rowExpansionTemplate = (data) => {
    return <PreviewDataMultiple previewData={{ ...data, courier_name: courierName }} />;
  };

  return (
    <>
      <div className="p-fluid formgrid grid card col-12 xl:col-11 mx-auto">
        <Toolbar
          className="mb-4 py-4 col-12"
          left={() => (
            <div className="px-2">
              <h1 className="text-xl uppercase p-0 m-0">Form Buat Pesanan Multiple</h1>
            </div>
          )}
        />
        <div className="col-12 flex justify-content-center steps-demo">
          <div className="w-full mx-auto mt-4 overflow-x-auto custom-scroll-bar pb-3 lg:pb-0">
            <Steps model={items} activeIndex={step} readOnly={true} />
          </div>
        </div>
        <div className="mt-6 col-12">
          <form onSubmit={handleSubmit(orderHandler)} className="p-fluid grid formgrid">
            <div className={classNames({ hidden: step !== 0 }, "w-full")}>
              <div className="col-12 mb-4">
                <div className="flex align-items-center" style={{ gap: "5px" }}>
                  <span>Download Template CSV</span>
                  <Button type="button" onClick={() => getOrderTemplate()} className="font-bold hover:underline w-auto p-button-text p-0 m-0" loading={loadingGetOrderTemplate} label="DISINI" />
                </div>
              </div>
              <SenderInformation watch={watch} setCourierName={setCourierName} control={control} formState={formState} reset={reset} getValues={getValues} />
              <div className="field col-12 ">
                {!courierName ? <Tooltip position="top" target=".label-upload-csv" /> : null}
                <label htmlFor="choose-kurir">Pilih File (Maks 100 data) :</label>
                <input accept=".csv" disabled={!courierName} size onChange={handleFileUpload} type="file" id="upload-csv" hidden />
                <label
                  data-pr-tooltip="Silahkan isi data diatas terlebih dahulu"
                  htmlFor="upload-csv"
                  className={classNames(
                    {
                      "p-button-primary": courierName,
                      "p-button-secondary": !courierName,
                    },
                    "p-button p-button-outlined label-upload-csv"
                  )}
                >
                  {loadingCheckFile ? (
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "1.1em" }}></i>
                  ) : (
                    <span disabled={true} className="font-bold uppercase">
                      <i className="pi pi-upload pr-2"></i>
                      {fileName ? fileName : "Upload File max 2MB"}
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className={classNames({ hidden: step !== 1 }, "w-full")}>
              <div className="col-12 flex flex-column lg:flex-row align-items-center justify-content-between mb-4">
                <div className="text-md">
                  Mohon periksa detail pengiriman dibawah ini. <br /> Jika benar lanjutkan dengan menekan tombol "Buat Pesanan" dibawah
                </div>
              </div>

              <div className="col-12 pb-2 font-bold">
                <p>TOTAL DATA : {previewData.length}</p>
              </div>
              <div className="col-12">
                <DataTable value={previewData} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} responsiveLayout="scroll" rowExpansionTemplate={rowExpansionTemplate} dataKey="receiver_info.receiver_name">
                  <Column expander={true} style={{ width: "3em" }} />
                  <Column className="capitalize" field="receiver_info.receiver_name" header="Nama Penerima"></Column>
                  <Column className="capitalize" body={() => courierName} header="Kurir"></Column>
                  <Column className="capitalize" field="service_type" header="Layanan"></Column>
                  <Column className="capitalize" field="receiver_info.receiver_city" header="Kota Tujuan"></Column>
                  <Column className="capitalize" field="item_name" header="Nama Barang"></Column>
                  <Column field="cod" body={(fields) => (fields.cod ? "Ya" : "Tidak")} header="COD"></Column>
                  <Column field="cod_price" body={(fields) => (fields.cod_price > 0 ? formatRupiah(fields.cod_price) : "-")} header="Harga COD"></Column>
                  <Column field="item_price" body={(fields) => (fields.item_price > 0 ? formatRupiah(fields.item_price) : "-")} header="Harga Non-Cod"></Column>
                </DataTable>
              </div>
            </div>
            {/* step control */}
            <div className="flex field justify-content-between mt-5 col-12" style={{ gap: "10px" }}>
              {step === 0 ? (
                <div className="">
                  <Button type="button" disabled={!successFileUrl} loading={loadingSummaryData} className="p-button-success" onClick={checkStepAndGetSummary} label="Selanjutnya" />
                </div>
              ) : (
                <>
                  <div className="">
                    <Button type="button" className="p-button-secondary" onClick={() => setStep((current) => current - 1)} label="Step Sebelumya" />
                  </div>
                  <div className="">
                    <Button loading={loadingRequestPickup || loadingGetIp} icon="pi pi-car" label="Buat Pesanan" />
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>

      {/* success create order */}
      <Dialog
        visible={showSuccessModal}
        className="modal-container"
        header="Notifikasi"
        onHide={() => {
          setShowSuccessModal(false);
          reset({ address: {} });
          setStep(0);
        }}
        footer={
          <Button
            label="Mengerti"
            className="p-button-success p-button-text"
            onClick={() => {
              setShowSuccessModal(false);
              reset({ address: {} });
              setStep(0);
            }}
          />
        }
      >
        <div className="flex mb-5">
          <p>
            Berhasil membuat pesanan silahkan cek pesanan di <br />
            <span className="font-bold underline cursor-pointer" onClick={() => navigate("/dashboard/order-history")}>
              Riwayat Pesanan
            </span>
          </p>
        </div>
      </Dialog>

      {/* some data error */}
      <Dialog
        visible={modalSomeDataError}
        className="modal-container"
        header="PERINGATAN"
        onHide={() => {
          setModalSomeDataError(false);
        }}
        footer={
          <div>
            <Button
              label="Download File & Tutup"
              className="p-button-success p-button-text"
              onClick={() => {
                downloadHandler(failedFileUrl, failedFileUrl);
                setModalSomeDataError(false);
              }}
            />
            <Button
              label="Tutup"
              className="p-button-secondary p-button-text"
              onClick={() => {
                setModalSomeDataError(false);
              }}
            />
          </div>
        }
      >
        <div className="flex mb-5">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          <p>Terjadi kesalahan pada beberapa data yang di-export. Silahkan download file untuk melihat data yang salah atau tutup peringatan ini dan abaikan data yang salah.</p>
        </div>
      </Dialog>

      {/* all data failed */}
      <Dialog
        visible={modalDataError}
        className="modal-container"
        header="ERROR"
        onHide={() => {
          setModalDataError(false);
        }}
        footer={
          <div>
            <Button
              loading={loadingCheckFile}
              label="Download File & Tutup"
              className="p-button-success p-button-text"
              onClick={() => {
                downloadHandler(failedFileUrl, failedFileUrl);
                setModalSomeDataError(false);
              }}
            />
            <Button
              label="Tutup"
              className="p-button-secondary p-button-text"
              onClick={() => {
                setModalDataError(false);
              }}
            />
          </div>
        }
      >
        <div className="flex mb-5">
          <i className="pi pi-exclamation-circle mr-3" style={{ fontSize: "2rem" }} />
          <p>
            {errorFileMessage} <br />
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default CreateOrderMultiple;
