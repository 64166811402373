import React from "react";
import WaLogo from "../assets/images/wa-icon.svg";

const WhatsAppFloat = () => {
  return (
    <a id="wa-floating" href={process.env.REACT_APP_WA_LINK} rel="noopener noreferrer" className="float" target="_blank">
      <img src={WaLogo} className="my-float" alt="wa-icon" />
    </a>
  );
};

export default WhatsAppFloat;
