// hooks
import React, { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

// utils
import TimeFormatter from "../utils/TimeFormatter";
import Api from "../api/Api";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";

const Couriers = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [services, setServices] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // api calling
  const { data: courierData, isLoading, refetch } = useQuery("couriers", async () => await getCouriers(), { defaultValue: [] });

  // query
  const { mutate: getServices } = useMutation(async (data) => await Api().post("/service-type/services", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setServices(response.data.data);
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("courier", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowCreateModal(false);
        reset({});
        toast.success("Courier Created!", { duration: 4000 });
      } else {
        refetch();
        setShowCreateModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/courier/edit", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowEditModal(false);
        toast.success("Courier Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("courier", { data: data }), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Courier Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const { handleSubmit, control, formState, reset } = useForm();
  const updateForm = useForm();

  // functions
  const getCouriers = async () => {
    const response = await Api().get("courier");
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data.data;
  };

  const create = (data) => {
    let special_city_data = [];

    if (data.special_city.length) {
      for (let i = 0; i < data.special_city.length; i++) {
        special_city_data.push(data.special_city[i].name);
      }
    }
    crateMutate({ ...data, special_city: special_city_data });
  };

  const update = (data) => {
    let special_city_data = [];

    if (data.special_city.length) {
      for (let i = 0; i < data.special_city.length; i++) {
        special_city_data.push(data.special_city[i].name);
      }
    }
    delete data.address;
    updateMutate({ ...data, courier_id: data._id, special_city: special_city_data });
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      courier_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.courier_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];
    let payload = {
      courier_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editHandler = async (data) => {
    let special_city_data = [];

    if (data.special_city.length) {
      for (let i = 0; i < data.special_city.length; i++) {
        special_city_data.push({ name: data.special_city[i] });
      }
    }
    await getServices({ courier_id: data._id });

    updateForm.reset({ ...data, special_city: special_city_data });
    setShowEditModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => setShowCreateModal(true)} />}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Kurir</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  const FormCourier = ({ control, disabledService }) => {
    const { fields, remove, append } = useFieldArray({
      control,
      name: "special_city",
    });

    return (
      <>
        <div className="field">
          <label htmlFor="name">Nama Ekspedisi : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="name"
            render={({ field }) => <InputText placeholder="Masukkan nama ekspedisi" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors?.name && "p-invalid"} />}
          />
          {formState.errors?.name && (
            <small id="name" className="p-error block pt-1">
              Masukkan nama ekspedisi
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="allow_service_type">Layanan : </label>
          <Controller
            control={control}
            name="allow_service_type"
            render={({ field }) => <MultiSelect emptyFilterMessage="Data tidak ditemukan" disabled={disabledService} value={field.value} options={services} onChange={(e) => field.onChange(e)} optionLabel="name" optionValue="name" placeholder={"Pilih Layanan"} display="chip" />}
          />
        </div>
        <div className="field">
          <label htmlFor="discount_percentage">Default Persentase Diskon (%): </label>
          <Controller
            control={control}
            name="discount_percentage"
            defaultValue={0}
            render={({ field }) => (
              <InputNumber
                minFractionDigits={2}
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan persentase diskon"
                className={classNames({
                  "p-invalid": formState.errors.discount_percentage,
                })}
              />
            )}
          />
          {formState.errors.discount_percentage && (
            <small id="discount_percentage" className="p-error block pt-1">
              Masukkan persentase diskon
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="tax_percentage">Default Persentase Pajak (%): </label>
          <Controller
            control={control}
            name="tax_percentage"
            defaultValue={0}
            render={({ field }) => (
              <InputNumber
                minFractionDigits={2}
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan persentase pajak"
                className={classNames({
                  "p-invalid": formState.errors.tax_percentage,
                })}
              />
            )}
          />
          {formState.errors.tax_percentage && (
            <small id="tax_percentage" className="p-error block pt-1">
              Masukkan persentase pajak
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="cod_percentage">Default Persentase COD (%): </label>
          <Controller
            control={control}
            name="cod_percentage"
            defaultValue={0}
            render={({ field }) => (
              <InputNumber
                minFractionDigits={2}
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan persentase cod"
                className={classNames({
                  "p-invalid": formState.errors.cod_percentage,
                })}
              />
            )}
          />
          {formState.errors.cod_percentage && (
            <small id="cod_percentage" className="p-error block pt-1">
              Masukkan persentase cod
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="insurance_percentage">Default Persentase Asuransi (%): </label>
          <Controller
            control={control}
            name="insurance_percentage"
            defaultValue={0}
            render={({ field }) => (
              <InputNumber
                minFractionDigits={2}
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan persentase cod"
                className={classNames({
                  "p-invalid": formState.errors.insurance_percentage,
                })}
              />
            )}
          />
          {formState.errors.insurance_percentage && (
            <small id="insurance_percentage" className="p-error block pt-1">
              Masukkan persentase Asuransi
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="margin_percentage">Default Persentase Margin (%): </label>
          <Controller
            control={control}
            name="margin_percentage"
            defaultValue={0}
            render={({ field }) => (
              <InputNumber
                minFractionDigits={2}
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan persentase cod"
                className={classNames({
                  "p-invalid": formState.errors.margin_percentage,
                })}
              />
            )}
          />
          {formState.errors.margin_percentage && (
            <small id="margin_percentage" className="p-error block pt-1">
              Masukkan persentase Margin
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="admin_fee">Default Biaya Admin : </label>
          <Controller
            control={control}
            name="admin_fee"
            defaultValue={0}
            minFractionDigits={2}
            render={({ field }) => (
              <InputNumber
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan persentase cod"
                className={classNames({
                  "p-invalid": formState.errors.admin_fee,
                })}
              />
            )}
          />
          {formState.errors.admin_fee && (
            <small id="admin_fee" className="p-error block pt-1">
              Masukkan persentase Biaya Admin
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="admin_fee">Minimal Harga COD : </label>
          <Controller
            control={control}
            name="min_cod_price"
            defaultValue={0}
            minFractionDigits={2}
            render={({ field }) => (
              <InputNumber
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan minimal harga cod"
                className={classNames({
                  "p-invalid": formState.errors.min_cod_price,
                })}
              />
            )}
          />
          {formState.errors.min_cod_price && (
            <small id="min_cod_price" className="p-error block pt-1">
              Masukkan minimal harga cod
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="admin_fee">Maksimal Harga COD : </label>
          <Controller
            control={control}
            name="max_cod_price"
            defaultValue={0}
            minFractionDigits={2}
            render={({ field }) => (
              <InputNumber
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan Maksimal harga cod"
                className={classNames({
                  "p-invalid": formState.errors.max_cod_price,
                })}
              />
            )}
          />
          {formState.errors.max_cod_price && (
            <small id="max_cod_price" className="p-error block pt-1">
              Masukkan Maksimal harga cod
            </small>
          )}
        </div>
        {fields.length ? (
          <div className="mt-4">
            <h5 className="m-0 uppercase" style={{ fontSize: "12px" }}>
              Kota Khusus
            </h5>
          </div>
        ) : null}
        <div className="field" style={{ gap: "5px" }}>
          {fields.map((item, index) => (
            <div className="w-full grid mt-1" key={item._id}>
              <div className="col-11 field mb-0">
                <label htmlFor="user">Nama Kota :</label>
                <Controller control={control} name={`special_city.${index}.name`} render={({ field }) => <InputText placeholder="masukkan nama kota" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" />} />
              </div>
              <div className="col-1 field  mb-0">
                <label className="">Action</label> <br />
                <Button icon="pi pi-times block" onClick={() => remove(index)} className=" p-button-danger" />
              </div>
            </div>
          ))}
        </div>
        <div className="field">
          <Button type="button" label="Tambah Kota Khusus" onClick={() => append({})} className=" p-button-primary mr-4" />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12 mx-auto">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={courierData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Kurir Tidak Tersedia"
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="name" header="Name" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column body={(field) => field.cod_percentage + "%"} field="cod_percentage" header="Default COD" sortable headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column body={(field) => field.discount_percentage + "%"} field="discount_percentage" header="Default Diskon" sortable headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column body={(field) => field.tax_percentage + "%"} field="tax_percentage" header="Default Pajak" sortable headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column body={(field) => field.insurance_percentage + "%"} field="insurance_percentage" header="Default Asuransi" sortable headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column field="admin_fee" header="Default Biaya Admin" sortable headerStyle={{ width: "auto", minWidth: "4rem" }}></Column>
              <Column body={(field) => TimeFormatter(field.created_at, "ll")} field="created_at" header="Dibuat" sortable headerStyle={{ width: "10%", minWidth: "5rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "auto", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} header="Create Kurir" modal className="p-fluid modal-container" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <FormCourier disabledService control={control} />
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} header="Edit Kurir" modal className="p-fluid modal-container" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <FormCourier control={updateForm.control} />
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Kurir?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Couriers, comparisonFn);
