import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Column } from "primereact/column";
// import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import formatRupiah from "../../utils/formatRupiah";
import Api from "../../api/Api";
import BalanceCard from "../../components/BalanceCard";
import dateFormatYYYYMMDD from "../../utils/dateFormatYYYYMMDD";

const Balance = () => {
  let date = new Date();
  let first_month_date = new Date(date.getFullYear(), date.getMonth(), 1);

  const [balanceData, setBalanceData] = useState({});
  const [rangeDate, setRangeDate] = useState([first_month_date, date]);
  const [minDate, setMinDate] = useState(first_month_date);
  const [maxDate, setMaxDate] = useState(date);

  const { isLoading, mutate: getBalance } = useMutation(async (data) => Api().post("/order-summary/balance", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      setBalanceData(response.data.data);
    },
  });

  useEffect(() => {
    let min_date = dateFormatYYYYMMDD(minDate);
    let max_date = maxDate ? dateFormatYYYYMMDD(maxDate) : min_date;

    getBalance({ start_date: min_date, end_date: max_date });
  }, [minDate, maxDate, getBalance]);

  return (
    <div className="w-full">
      <div className="card">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-6">
            <div className="">
              <p className="font-medium uppercase">Saldo Saya :</p>
              <h4 className="uppercase font-bold">{formatRupiah(0)}</h4>
            </div>
            <div className="flex justify-content-between md:justify-content-start w-full mt-4" style={{ gap: "20px" }}>
              <Button disabled className="uppercase" label="Top up saldo" />
              <Button disabled className="uppercase p-button-secondary" label="Tarik Saldo" />
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-6 flex flex-column xl:flex-row p-0">
            <BalanceCard isLoading={isLoading} icon="fa-money-bill-transfer" className="col-12 xl:col-6" title="Proses Penarikan Dana" balance={balanceData.fee_cod_nonrtp} />
            <BalanceCard isLoading={isLoading} icon="fa-clock-rotate-left" className="col-12 xl:col-6" title="Saldo Pending" balance={0} />
          </div>
        </div>
      </div>

      {/* widget */}
      <div className="mt-4 py-4 flex grid ">
        <div className="col-12 md:col-6 xl:col-4">
          <label htmlFor="choose-kurir" className="pb-2 block uppercase">
            Balance Report:
          </label>
          <Calendar
            id="range"
            className="w-full"
            selectionMode="range"
            placeholder="Pilih Periode Waktu"
            value={rangeDate}
            onChange={(e) => {
              setRangeDate(e.value);
              setMinDate(e.value[0]);
              setMaxDate(e.value[1]);
            }}
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="grid text-sm">
        <BalanceCard isLoading={isLoading} icon="fa-money-bill-wave" className="col-12 md:col-6 xl:col-4" title="DANA SELESAI DITARIK" balance={0} />
        <BalanceCard isLoading={isLoading} icon="fa-money-bill-wave" className="col-12 md:col-6 xl:col-4" title="TOTAL SEMUA TRANSAKSI COD" balance={balanceData.fee_cod_nonrtp} />
        <BalanceCard isLoading={isLoading} icon="fa-money-bill-wave" className="col-12 md:col-6 xl:col-4" title="DANA TERKUMPUL DARI PELANGGAN (Kotor)" balance={balanceData.fee_cod_delivered} />
        <BalanceCard isLoading={isLoading} icon="fa-money-bill-wave" className="col-12 md:col-6 xl:col-4" title="DANA TERKUMPUL DARI PELANGGAN (Bersih)" balance={balanceData.fee_cod_delivered_clean} />
        <BalanceCard isLoading={isLoading} icon="fa-people-carry-box" className="col-12 md:col-6 xl:col-4" title="ONGKOS KIRIM COD (Kotor)" balance={balanceData.shipping_cost_cod_nonrtp} />
        <BalanceCard isLoading={isLoading} icon="fa-people-carry-box" className="col-12 md:col-6 xl:col-4" title="ONGKOS KIRIM COD (Bersih)" balance={balanceData.shipping_cost_cod_nonrtp_clean} />
        <BalanceCard isLoading={isLoading} icon="fa-percent" className="col-12 md:col-6 xl:col-4" title="DISCOUNT ONGKIR COD" balance={balanceData.fee_discount_cod} />
        <BalanceCard isLoading={isLoading} icon="fa-truck" className="col-12 md:col-6 xl:col-4" title="ONGKOS KIRIM NON COD (Kotor)" balance={balanceData.shipping_cost_noncod_nonrtp} />
        <BalanceCard isLoading={isLoading} icon="fa-truck" className="col-12 md:col-6 xl:col-4" title="ONGKOS KIRIM NON COD (Bersih)" balance={balanceData.shipping_cost_noncod_nonrtp_clean} />
        <BalanceCard isLoading={isLoading} icon="fa-percent" className="col-12 md:col-6 xl:col-4" title="DISCOUNT ONGKIR NON COD" balance={balanceData.fee_discount_noncod} />
        <BalanceCard isLoading={isLoading} icon="fa-rotate-left" className="col-12 md:col-6 xl:col-4" title="HEMAT BIAYA DELIVERY & RETURN" balance={balanceData.fee_return} />
        <BalanceCard isLoading={isLoading} icon="fa-rotate-left" className="col-12 md:col-6 xl:col-4" title="BIAYA RETURNED" balance={balanceData.fee_returned} />
      </div>

      {/* <DataTable value={[]} responsiveLayout="scroll">
        <Column field="code" header="Code"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="category" header="Category"></Column>
        <Column field="quantity" header="Quantity"></Column>
      </DataTable> */}
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Balance, comparisonFn);
