// hooks
import React, { useEffect, useState } from "react";

// components
import { Toolbar } from "primereact/toolbar";
import { Steps } from "primereact/steps";
import SenderInformation from "../../components/requestpickup/form/SenderInformation";
import ReciverInformation from "../../components/requestpickup/form/ReciverInformation";
import ProductInformation from "../../components/requestpickup/form/ProductInformation";
import { PreviewData } from "../../components/requestpickup/form/PreviewData";
import { Button } from "primereact/button";

// utils
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Api from "../../api/Api";
import { useMutation } from "react-query";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import getClientIP4 from "../../utils/getClientIP4";

// data
const items = [
  {
    label: "Informasi Pengirim",
  },
  {
    label: "Informasi Penerima",
  },
  {
    label: "Informasi Barang",
  },
  {
    label: "Ringkasan Pengiriman",
  },
];

const CreateOrder = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [originCode, setOriginCode] = useState(null);
  const [estimatePrice, setEstimatePrice] = useState({});
  const [destinationCode, setDestinationCode] = useState(0);
  const [previewData, setPreviewData] = useState({});
  const [courierName, setCourierName] = useState("");
  const [loadingCheckEstimate, setLoadingCheckEstimate] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [loadingGetIp, setLoadingGetIp] = useState(false);
  const { control, handleSubmit, formState, reset, trigger, getValues, watch } = useForm();

  const { isLoading: loadingRequestPickup, mutate: requestPickUp } = useMutation(async (data) => await Api().post("/courier/pickupV2", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal request pickup");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }

      reset({});
      setShowSuccessModal(true);
    },
  });

  const orderHandler = async (data) => {
    let shipper_info = {};
    shipper_info.shipper_phone = data.address.phone_number;
    shipper_info.shipper_email = data.address.email;
    shipper_info.shipper_name = data.address.name;
    shipper_info.shipper_address = data.address.address;
    shipper_info.shipper_province = data.address.province;
    shipper_info.shipper_city = data.address.city;
    shipper_info.shipper_district = data.address.district;
    shipper_info.shipper_urban = data.address.urban;
    shipper_info.shipper_zip = data.address.postal_code;
    shipper_info.shipper_country = data.address.country ? data.address.country : null;
    delete data.address;
    let final_data_mapping = mappingData({ ...data, shipper_info });

    // alert if estimated_price is empty
    if (!Object.keys(final_data_mapping.body.estimated_price).length) {
      return toast.error("Data tidak lengkap, mohon untuk cek estimasi biaya kirim");
    }

    try {
      setLoadingGetIp(true);
      const response = await getClientIP4();
      if (response.status !== 200) {
        throw new Error("failed");
      }
      setLoadingGetIp(false);
      requestPickUp({ ...final_data_mapping, ip_address: response.data.IPv4 });
    } catch (error) {
      setLoadingGetIp(false);
      requestPickUp({ ...final_data_mapping, ip_address: null });
    }
  };

  const mappingData = (data) => {
    if (!Object.keys(data).length) return {};

    let temp = { courier_id: null, body: {} };

    // mapping data
    temp.body = { estimated_price: estimatePrice, shipper_info: { ...data.shipper_info }, receiver_info: { ...data.receiver_info } };
    delete data.shipper_info;
    delete data.receiver_info;
    temp = {
      body: { ...temp.body, ...data },
    };
    temp.courier_id = temp.body.courier_id;
    delete temp.body.courier_id;

    temp.body = {
      ...temp.body,
      receiver_info: {
        ...temp.body.receiver_info,
        receiver_province: temp.body.receiver_info.full_address.province,
        receiver_city: temp.body.receiver_info.full_address.city,
        receiver_district: temp.body.receiver_info.full_address.district,
        receiver_urban: temp.body.receiver_info.full_address.urban,
        receiver_zip: temp.body.receiver_info.full_address.postal_code,
        receiver_country: temp.body.receiver_info.full_address.country,
      },
    };

    return temp;
  };

  const handleNextStep = async () => {
    let isValid = false;

    switch (step) {
      case 0:
        isValid = await trigger(["courier_id", "service_type", "address", "pickup_date"]);
        break;
      case 1:
        isValid = await trigger(["receiver_info.receiver_name", "receiver_info.receiver_phone", "receiver_info.receiver_address", "receiver_info.full_address"]);
        break;
      case 2:
        isValid = await trigger(["item_qty", "item_price", "cod_price", "item_weight", "insurance_price", "item_name", "item_category"]);
        break;
      default:
        break;
    }

    if (isValid) {
      setStep((currentStep) => currentStep + 1);
    }
  };

  useEffect(() => {
    if (step === 3) {
      setPreviewData({ ...getValues(), estimated_price: estimatePrice, courier_name: courierName });
    }

    if (step === 2) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="p-fluid formgrid grid card col-12 xl:col-11 mx-auto">
        <Toolbar
          className="mb-4 py-4 col-12"
          left={() => (
            <div className="px-2">
              <h1 className="text-xl uppercase p-0 m-0">Form Buat Pesanan</h1>
            </div>
          )}
        />
        <div className="col-12 flex justify-content-center steps-demo">
          <div className="w-full mx-auto mt-4 overflow-x-auto custom-scroll-bar pb-3 lg:pb-0">
            <Steps model={items} activeIndex={step} readOnly={true} />
          </div>
        </div>
        <div className="mt-6 col-12">
          <form onSubmit={handleSubmit(orderHandler)} className="p-fluid grid formgrid">
            <div className={classNames({ hidden: step !== 0 }, "w-full")}>
              <SenderInformation watch={watch} setCourierName={setCourierName} control={control} formState={formState} reset={reset} getValues={getValues} setOriginCode={setOriginCode} />
            </div>
            <div className={classNames({ hidden: step !== 1 }, "w-full grid m-0")}>
              <ReciverInformation control={control} formState={formState} watch={watch} reset={reset} getValues={getValues} setDestinationCode={setDestinationCode} />
            </div>
            <div className={classNames({ hidden: step !== 2 }, "w-full")}>
              <ProductInformation
                setDisableButton={setDisableButton}
                step={step}
                watch={watch}
                setLoadingCheckEstimate={setLoadingCheckEstimate}
                setEstimatePrice={setEstimatePrice}
                control={control}
                formState={formState}
                origin={originCode}
                destination={destinationCode}
                values={step === 2 && getValues()}
                getValues={getValues}
                reset={reset}
              />
            </div>
            <div className={classNames({ hidden: step !== 3 }, "w-full")}>
              <div className="col-12 flex flex-column lg:flex-row justify-content-between mb-4">
                <div className="text-md">
                  Mohon periksa detail pengiriman dibawah ini. <br /> Jika benar lanjutkan dengan menekan tombol "Buat Pesanan"
                </div>
                <div className="col-12 md:col-3 lg:col-4 xl:col-2 mt-4 lg:mt-0">
                  <Button loading={loadingRequestPickup || loadingGetIp} disabled={loadingRequestPickup || loadingGetIp} icon="pi pi-car" label="Buat Pesanan" />
                </div>
              </div>
              <PreviewData previewData={previewData} />
            </div>
            <div className="flex field justify-content-between mt-5 col-12" style={{ gap: "10px" }}>
              {step > 0 && (
                <div className="">
                  <Button type="button" className="p-button-secondary" onClick={() => setStep((step) => step - 1)} label="Step Sebelumnya" />
                </div>
              )}
              {step < 3 && (
                <div className="">
                  <Button type="button" disabled={disableButton} className="p-button-success" onClick={handleNextStep} label="Selanjutnya" loading={loadingCheckEstimate} />
                </div>
              )}
              {step === 3 && (
                <div className="">
                  <Button loading={loadingRequestPickup || loadingGetIp} disabled={loadingRequestPickup || loadingGetIp} className="p-button-success" icon="pi pi-car" label="Buat Pesanan" />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <Dialog
        visible={showSuccessModal}
        className="modal-container"
        header="Notifikasi"
        onHide={() => {
          setShowSuccessModal(false);
          reset({ address: {} });
          setStep(0);
        }}
        footer={
          <Button
            label="Mengerti"
            className="p-button-success p-button-text"
            onClick={() => {
              setShowSuccessModal(false);
              reset({ address: {} });
              setStep(0);
            }}
          />
        }
      >
        <div className="flex mb-5">
          <p>
            Berhasil membuat pesanan silahkan cek pesanan di <br />
            <span className="font-bold underline cursor-pointer" onClick={() => navigate("/dashboard/order-history")}>
              Riwayat Pesanan
            </span>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default CreateOrder;
