// hooks
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import PDF from "../../components/PDF/PDF";
import { Ripple } from "primereact/ripple";

// utils
import { useNavigate } from "react-router-dom";
import TimeFormatter from "../../utils/TimeFormatter";
import Api from "../../api/Api";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { pdf } from "@react-pdf/renderer";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import statusOptions from "../../order-status.json";
import dateFormatYYYYMMDD from "../../utils/dateFormatYYYYMMDD";

const OrderHistory = ({ permissions }) => {
  let date = new Date();
  let first_month_date = new Date(date.getFullYear(), date.getMonth(), 1);
  const navigate = useNavigate();

  // state
  const [selectItems, setSelectItems] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [couriersOption, setCouriersOption] = useState([]);
  const { control, watch, reset, getValues } = useForm();
  const [showResiModal, setShowResiModal] = useState(false);
  const [showResiErrorModal, setShowResiErrorModal] = useState(false);
  const [resiErrorMessage, setResiErrorMessage] = useState("");
  const [tempResi, setTempResi] = useState("");
  const [resiData, setResiData] = useState({});
  const [rangeDate, setRangeDate] = useState([first_month_date, date]);
  const [minDate, setMinDate] = useState(first_month_date);
  const [maxDate, setMaxDate] = useState(date);
  const [query, setQuery] = useState(null);

  // state for paggin
  const pageOption = [25, 50, 100];
  const [totalData, setTotalData] = useState(0);
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const courier_id = watch("courier_id");
  const query_value = watch("query");

  // api calling
  const { isLoading, mutate: getOrderHistory } = useMutation(async (data) => Api().post("/order/user", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      setTotalData(response.data.pages.total_data);
      setOrderList(response.data.data);
    },
  });

  const { isLoading: loadingMultiplePrint, mutate: getMultiplePrint } = useMutation(async (data) => Api().post("/order/multiple-print", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      const multipleResiName = getNameMultipleResi();
      getPdfBlob(response.data.data, multipleResiName);
      setSelectItems([]);
    },
  });

  // functions
  const getCouriers = async () => {
    const response = await Api().get("courier");
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    let temp_data = [{ label: "Semua", value: "all" }];
    for (let i = 0; i < response.data.data.length; i++) {
      temp_data.push({ label: response.data.data[i].name, value: response.data.data[i]._id });
    }

    setCouriersOption(temp_data);
    return response.data.data;
  };

  const getNameMultipleResi = () => {
    return `Label-${TimeFormatter(new Date(), "YYMMDDhmmss")}`;
  };

  const getPdfBlob = async (data, fileName) => {
    let blobPdf = await pdf(PDF({ pdfData: data })).toBlob();
    var fileURL = URL.createObjectURL(blobPdf);
    var a = document.createElement("a");
    a.href = fileURL;
    a.target = "_blank";
    a.download = fileName;
    a.click();
  };

  const pagginHandler = () => {
    let min_date = dateFormatYYYYMMDD(minDate);
    let max_date = maxDate ? dateFormatYYYYMMDD(maxDate) : min_date;

    let payload = {
      page: currentPage,
      limit: limit,
      user_id: null,
      courier_id: null,
      start_date: min_date,
      query: query,
      end_date: max_date,
    };

    if (courier_id !== "all") {
      payload.courier_id = courier_id;
    }

    // if user role id is not super admin then dont show all order history only show user history
    // if (userStore.user.role_id !== process.env.REACT_APP_SUPER_ADMIN_ID || userStore.user.role_id !== process.env.REACT_APP_ADMIN_ID) {
    //   payload.user_id = userStore.user._id;
    // }

    getOrderHistory(payload);
  };

  const handleQueySearch = (e) => {
    e.preventDefault();
    setQuery(query_value);
  };

  useEffect(() => {
    getCouriers();
  }, []);

  useEffect(() => {
    pagginHandler();
  }, [currentPage, limit, courier_id, minDate, maxDate, query]); // eslint-disable-line react-hooks/exhaustive-deps

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h5 className="m-0">Riwayat Order</h5>
        </div>
      </React.Fragment>
    );
  };

  const ActionBodyTemplate = ({ rowData }) => {
    const { isLoading: isLoadingGetResi, mutate: getResiDetail } = useMutation(async (data) => await Api().post("/courier/track", data), {
      onSettled: (res, error) => {
        if (error) {
          return toast.error("Gagal melacak resi");
        }

        if (res.data.status !== 200) {
          const payload = JSON.parse(res.config.data);
          setTempResi(payload.resi);
          setResiErrorMessage(res.data.message);
          setShowResiErrorModal(true);
          // return toast.error(res.data.message);
          return;
        }

        setResiData(res.data.data);
        setShowResiModal(true);
      },
    });
    const { isLoading, mutate: getMultiplePrint } = useMutation(async (data) => Api().post("/order/multiple-print", data), {
      onSettled: (response) => {
        if (response.data.status !== 200) {
          toast.error(response.data.message);
          return;
        }

        getPdfBlob(response.data.data, `Label-${rowData.resi}`);
      },
    });

    return (
      <div className="actions grid" style={{ gap: "8px" }}>
        {permissions.view && <Button tooltip="Cek Resi" tooltipOptions={{ position: "top" }} loading={isLoadingGetResi} icon="pi pi-car" className="p-button-rounded p-button-secondary" onClick={() => getResiDetail({ courier_id: rowData.courier_id._id, resi: rowData.resi })} />}
        <Button onClick={() => getMultiplePrint({ order_ids: [rowData._id] })} tooltip="Cetak Resi" tooltipOptions={{ position: "top" }} icon="pi pi-print" className="p-button-rounded p-button-success" loading={isLoading} />
        {permissions.view && <Button tooltip="Detail" tooltipOptions={{ position: "top" }} icon="pi pi-eye" className="p-button-rounded p-button-warning mr-2" onClick={() => navigate(`/dashboard/order-history/details/${rowData._id}`)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column ">
        <div className="p-fluid  grid ">
          <div className="col-6 lg:col-6 xl:col-4">
            <label htmlFor="choose-kurir" className="pb-2 block">
              Pilih Kurir :
            </label>
            <Controller
              control={control}
              name="courier_id"
              render={({ field }) => (
                <Dropdown
                  ref={field.ref}
                  disabled={!couriersOption.length}
                  optionLabel="label"
                  optionValue="value"
                  value={field.value}
                  onBlur={field.onBlur}
                  options={couriersOption}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  placeholder="Pilih Kurir"
                />
              )}
            />
          </div>
          <div className="col-6 lg:col-6 xl:col-4">
            <label htmlFor="choose-kurir" className="pb-2 block">
              Periode Waktu :
            </label>
            <Controller
              control={control}
              name="test"
              render={({ field }) => (
                <div className="relative flex align-items-center w-full">
                  <Calendar
                    id="range"
                    className="w-full"
                    selectionMode="range"
                    placeholder="Pilih periode waktu"
                    maxDate={new Date()}
                    value={rangeDate}
                    onChange={(e) => {
                      setRangeDate(e.value);
                      setMinDate(e.value[0]);
                      setMaxDate(e.value[1]);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <form className="p-fluid grid mt-2" onSubmit={handleQueySearch}>
          <div className="col-12 md:col-6  xl:col-8">
            <label htmlFor="query" className="pb-2 block">
              Pencarian : (min 4 karakter){" "}
            </label>
            <div className="relative w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="query"
                render={({ field }) => <InputText placeholder="Cari Resi / Kota Tujuan atau Penerima" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="query" type="text" />}
              />
              <div className="absolute top-0 text-center flex right-0">
                {query_value?.length ? (
                  <Button
                    onClick={() => {
                      setQuery("");
                      reset({ ...getValues(), query: "" });
                    }}
                    type="button"
                    className="w-full h-full p-button-danger"
                    label="X"
                    tooltip="Hapus"
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="field col-6 md:col-3 xl:col-2">
            <label style={{ visibility: "hidden" }}>-</label>
            <Button disabled={query_value?.length < 4} label="Cari" className="p-button-success" loading={isLoading} />
          </div>
          <div className="field col-6 md:col-3 xl:col-2">
            <label style={{ visibility: "hidden" }}>-</label>
            <Button
              onClick={() => {
                reset({});
                setQuery(null);
              }}
              type="button"
              label="Hapus Filter"
              className="p-button-danger"
            />
          </div>
        </form>
      </div>
    );
  };

  const statusField = (fields) => {
    let status = { value: null, text: "-", severity: "#fb5252" };

    if (fields.status) {
      let findStatus = statusOptions.find((data) => data.value === fields.status);
      if (findStatus) {
        status = findStatus;
      }
    }

    return (
      <div className="p-tag p-component w-full" style={{ height: "25px", backgroundColor: status.severity }}>
        {status.text}
      </div>
    );
  };

  const pagginatorTemplate = {
    layout: "RowsPerPageDropdown CurrentPageReport   PrevPageLink NextPageLink",
    PrevPageLink: (options) => {
      const prevHandler = () => {
        setCurrentPage((prev) => prev - 1);
      };
      return (
        <button
          type="button"
          className={classNames(
            {
              "p-disabled": currentPage <= 1,
            },
            "p-paginator-next p-paginator-element p-link"
          )}
          onClick={prevHandler}
          disabled={currentPage === 1}
        >
          <span className="p-3">Sebelumnya</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      const nextHandler = () => {
        setCurrentPage((prev) => prev + 1);
      };

      return (
        <button
          type="button"
          className={classNames(
            {
              "p-disabled": currentPage === Math.ceil(totalData / limit),
            },
            options.className
          )}
          disabled={currentPage === Math.ceil(totalData / limit)}
          onClick={nextHandler}
        >
          <span className="p-3">Selanjutnya</span>
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={pageOption}
            onChange={(e) => {
              setCurrentPage(1);
              setLimit(e.value);
            }}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: "var(--text-color)", userSelect: "none", width: "120px", textAlign: "center" }}>
          {currentPage} - {Math.ceil(totalData / limit)} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={
                <div className="">
                  <Button
                    onClick={() => getMultiplePrint({ order_ids: selectItems })}
                    disabled={!selectItems.length}
                    label={`Cetak ${selectItems.length}`}
                    tooltipOptions={{ position: "top" }}
                    tooltip="Cetak sekaligus"
                    icon="pi pi-print"
                    loading={loadingMultiplePrint}
                    className="p-button-success mr-2"
                  />
                </div>
              }
            ></Toolbar>
            <DataTable
              loading={isLoading}
              value={orderList}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              rows={pageOption}
              rowsPerPageOptions={pageOption}
              className="datatable-responsive"
              emptyMessage="Riwayat order tidak ditemukan"
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="resi" header="Resi" sortable headerStyle={{ width: "auto", minWidth: "8rem" }}></Column>
              <Column field="receiver_info.receiver_name" header="Penerima" sortable headerStyle={{ width: "auto" }}></Column>
              <Column field="receiver_info.receiver_city" header="Kota Tujuan" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column body={(field) => `${field.courier_id.name} (${field.service_type})`} field="courier_id.name" header="Kurir" sortable headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column body={(field) => (field.cod ? "YA" : "TIDAK")} field="cod" sortable header="COD" headerStyle={{ width: "auto", minWidth: "3rem" }}></Column>
              <Column field="created_at" body={(field) => TimeFormatter(field.created_at, "lll")} header="Waktu Order" sortable headerStyle={{ width: "10%", minWidth: "8rem" }}></Column>
              <Column field="pickup_date" body={(field) => TimeFormatter(field.pickup_date, "lll")} header="Waktu Pickup" sortable headerStyle={{ width: "10%", minWidth: "8rem" }}></Column>
              <Column style={{ minWidth: "10rem" }} field="status" header="Status" body={statusField}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={(data) => <ActionBodyTemplate rowData={data} />} headerStyle={{ width: "15%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
            <Paginator template={pagginatorTemplate} first={1} rows={limit} totalRecords={totalData}></Paginator>
          </div>
        </div>
      </div>

      {/* resi check */}
      <Dialog
        visible={showResiModal}
        style={{ width: "650px" }}
        header="Detail Resi"
        modal
        draggable={false}
        footer={() => (
          <div className="mt-4">
            <Button onClick={() => setShowResiModal(false)} label="Tutup" className="p-button-secondary" />
          </div>
        )}
        onHide={() => {
          setShowResiModal(false);
          setResiData({});
        }}
      >
        <div className="user-destination-wrapper grid">
          <div className="col-12">
            {resiData?.track_history?.length ? (
              <table style={{ borderSpacing: "5px" }}>
                <tr>
                  <td style={{ minWidth: "150px" }}>Resi</td>
                  <td> : </td>
                  <td className="font-bold">{resiData.waybill}</td>
                </tr>
                <tr>
                  <td style={{ minWidth: "150px" }}>Tujuan Awal</td>
                  <td> : </td>
                  <td>{resiData.origin}</td>
                </tr>
                <tr>
                  <td style={{ minWidth: "150px" }}>Tujuan Akhir</td>
                  <td> : </td>
                  <td>{resiData.destination}</td>
                </tr>
                <tr>
                  <td style={{ minWidth: "150px" }}>Nama Penerima</td>
                  <td> : </td>
                  <td>{resiData.receiver_name}</td>
                </tr>
                <tr>
                  <td style={{ minWidth: "150px" }}>Status</td>
                  <td> : </td>
                  <td>{resiData.status}</td>
                </tr>
                <tr>
                  <td style={{ minWidth: "150px" }}>Berat</td>
                  <td> : </td>
                  <td>{resiData.weight} Kg</td>
                </tr>
                <tr>
                  <td style={{ minWidth: "150px" }}>Di Terima Pada</td>
                  <td> : </td>
                  <td>{resiData.pod_date ? TimeFormatter(resiData.pod_date, "lll") : "-"}</td>
                </tr>
                <tr>
                  <td style={{ minWidth: "150px" }} width={50}>
                    Riwayat Terakhir
                  </td>
                  <td> : </td>
                  <td>
                    {resiData?.track_history[resiData?.track_history?.length - 1]?.description} <span className="font-bold">{resiData?.track_history[resiData?.track_history?.length - 1]?.date ? TimeFormatter(resiData?.track_history[resiData?.track_history?.length - 1]?.date, "lll") : "-"}</span>
                  </td>
                </tr>
                {resiData.pod_photo ? (
                  <tr>
                    <td style={{ minWidth: "150px" }}>Bukti</td>
                    <td> : </td>
                    <td>{resiData.pod_photo ? <Image src={resiData.pod_photo} alt="pod" width="120" height="120" preview /> : ""}</td>
                  </tr>
                ) : null}
              </table>
            ) : (
              <p className="">
                RESI : <b>{resiData.waybill}</b>, Sedang Diperoses.
              </p>
            )}
          </div>
        </div>
      </Dialog>
      {/* resi error */}
      <Dialog
        visible={showResiErrorModal}
        style={{ width: "650px" }}
        header="Detail Resi"
        modal
        draggable={false}
        footer={() => (
          <div className="mt-4">
            <Button onClick={() => setShowResiErrorModal(false)} label="Tutup" className="p-button-secondary" />
          </div>
        )}
        onHide={() => {
          setShowResiErrorModal(false);
          setTempResi("");
          setResiErrorMessage("");
        }}
      >
        <div className="user-destination-wrapper grid">
          <div className="col-12">
            <p className="">
              RESI : <b>{tempResi}</b>, {resiErrorMessage}
            </p>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(OrderHistory, comparisonFn);
