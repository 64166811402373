// hooks
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Ripple } from "primereact/ripple";

// utils
import TimeFormatter from "../../utils/TimeFormatter";
import Api from "../../api/Api";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Paginator } from "primereact/paginator";
import formatRupiah from "../../utils/formatRupiah";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useUserStore from "../../stores/userStore";
import statusOptions from "../../order-status.json";
import dateFormatYYYYMMDD from "../../utils/dateFormatYYYYMMDD";

const layananOptions = [
  { value: null, text: "ALL" },
  { value: 1, text: "COD" },
  { value: 2, text: "NON-COD" },
];
const Report = ({ permissions }) => {
  const userStore = useUserStore((state) => state);
  const isSuperAdmin = userStore.user.role_id === process.env.REACT_APP_SUPER_ADMIN_ID;

  let date = new Date();
  let first_month_date = new Date(date.getFullYear(), date.getMonth(), 1);

  // state
  const [selectItems, setSelectItems] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [couriersOption, setCouriersOption] = useState([]);
  const { control, watch, getValues, reset } = useForm();
  const [rangeDate, setRangeDate] = useState([first_month_date, date]);
  const [minDate, setMinDate] = useState(first_month_date);
  const [maxDate, setMaxDate] = useState(date);
  const [query, setQuery] = useState(null);

  // state for paggin
  const pageOption = [25, 50, 100];
  const [totalData, setTotalData] = useState(0);
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const courier_id = watch("courier_id");
  const cod_status = watch("cod");
  const status_value = watch("status");
  const query_value = watch("query");

  // api calling
  const { isLoading, mutate: getOrderHistory } = useMutation(async (data) => Api().post("/order/user", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      setTotalData(response.data.pages.total_data);
      setOrderList(response.data.data);
    },
  });
  const { isLoading: loadingExportCSV, mutate: getCSV } = useMutation(async (data) => Api().post("/order/export", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      getPdfBlob(response.data.data, "test");
    },
  });

  // functions
  const getCouriers = async () => {
    const response = await Api().get("courier");
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    let temp_data = [{ label: "Semua", value: "all" }];
    for (let i = 0; i < response.data.data.length; i++) {
      temp_data.push({ label: response.data.data[i].name, value: response.data.data[i]._id });
    }

    setCouriersOption(temp_data);
    return response.data.data;
  };

  const pagginHandler = () => {
    let min_date = dateFormatYYYYMMDD(minDate);
    let max_date = maxDate ? dateFormatYYYYMMDD(maxDate) : min_date;

    let payload = {
      page: currentPage,
      limit: limit,
      user_id: null,
      courier_id: null,
      start_date: min_date,
      cod: cod_status,
      end_date: max_date,
      status: status_value,
      query: query,
    };

    if (courier_id !== "all") {
      payload.courier_id = courier_id;
    }

    getOrderHistory(payload);
  };

  const handleQueySearch = (e) => {
    e.preventDefault();
    setQuery(query_value);
  };

  const csvHandler = () => {
    let ids = [];

    for (let i = 0; i < selectItems.length; i++) {
      ids.push(selectItems[i]._id);
    }

    const payload = {
      seller: true,
      internal: false,
      start_date: minDate,
      end_date: maxDate ? maxDate : minDate,
      user_id: null,
      courier_id: courier_id,
      service_type: null,
      status: status_value,
      cod: cod_status,
      query: query,
      selected_ids: ids,
    };

    getCSV(payload);
  };

  const csvAdminHandler = () => {
    let ids = [];

    for (let i = 0; i < selectItems.length; i++) {
      ids.push(selectItems[i]._id);
    }

    const payload = {
      seller: false,
      internal: true,
      start_date: minDate,
      end_date: maxDate ? maxDate : minDate,
      user_id: null,
      courier_id: courier_id,
      service_type: null,
      status: status_value,
      cod: cod_status,
      query: query,
      selected_ids: ids,
    };

    getCSV(payload);
  };

  const getPdfBlob = async (url, fileName) => {
    var a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName;
    a.click();
  };

  useEffect(() => {
    getCouriers();
  }, []);

  useEffect(() => {
    if (!query_value?.length) {
      setQuery(null);
    }
  }, [query_value]);

  useEffect(() => {
    pagginHandler();
  }, [currentPage, limit, courier_id, minDate, maxDate, cod_status, status_value, query]); // eslint-disable-line react-hooks/exhaustive-deps

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h5 className="m-0">Laporan</h5>
        </div>
      </React.Fragment>
    );
  };

  const statusField = (fields) => {
    let status = { value: null, text: "-", severity: "#fb5252" };

    if (fields.status) {
      let findStatus = statusOptions.find((data) => data.value === fields.status);
      if (findStatus) {
        status = findStatus;
      }
    }

    return (
      <div className="p-tag p-component col-8" style={{ backgroundColor: status.severity }}>
        {status.text}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column">
        <div className="p-fluid grid ">
          <div className="col-6 lg:col-6 xl:col-3">
            <label htmlFor="choose-kurir" className="pb-2 block">
              Periode Waktu :
            </label>
            <Controller
              control={control}
              name="periode"
              render={({ field }) => (
                <div className="relative flex align-items-center w-full">
                  <Calendar
                    id="range"
                    className="w-full"
                    selectionMode="range"
                    placeholder="Pilih periode waktu"
                    maxDate={new Date()}
                    value={rangeDate}
                    onChange={(e) => {
                      setRangeDate(e.value);
                      setMinDate(e.value[0]);
                      setMaxDate(e.value[1]);
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className="col-6 lg:col-6 xl:col-3">
            <label htmlFor="choose-kurir" className="pb-2 block">
              Pilih Kurir :
            </label>
            <Controller
              control={control}
              name="courier_id"
              render={({ field }) => (
                <Dropdown
                  ref={field.ref}
                  disabled={!couriersOption.length}
                  optionLabel="label"
                  optionValue="value"
                  value={field.value}
                  onBlur={field.onBlur}
                  options={couriersOption}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  placeholder="Pilih Kurir"
                />
              )}
            />
          </div>
          <div className="col-6 lg:col-6 xl:col-3">
            <label htmlFor="choose-kurir" className="pb-2 block">
              Pilih Layanan :
            </label>
            <Controller
              control={control}
              name="cod"
              render={({ field }) => (
                <Dropdown
                  ref={field.ref}
                  disabled={!layananOptions.length}
                  optionLabel="text"
                  optionValue="value"
                  value={field.value}
                  onBlur={field.onBlur}
                  options={layananOptions}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  placeholder="Pilih Layanan"
                />
              )}
            />
          </div>
          <div className="col-6 lg:col-6 xl:col-3">
            <label htmlFor="choose-kurir" className="pb-2 block">
              Status Terakhir :
            </label>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Dropdown
                  ref={field.ref}
                  disabled={!statusOptions.length}
                  optionLabel="text"
                  optionValue="value"
                  value={field.value}
                  onBlur={field.onBlur}
                  options={statusOptions}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  placeholder="Pilih Status Terakhir"
                />
              )}
            />
          </div>
        </div>
        <form className="p-fluid grid mt-2" onSubmit={handleQueySearch}>
          <div className="col-12 md:col-6  xl:col-8">
            <label htmlFor="query" className="pb-2 block">
              Pencarian : (min 4 karakter){" "}
            </label>
            <div className="relative w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="query"
                render={({ field }) => <InputText placeholder="Cari Resi / Kota Tujuan atau Penerima" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="query" type="text" />}
              />
              <div className="absolute top-0 text-center flex right-0">
                {query_value?.length ? (
                  <Button
                    onClick={() => {
                      setQuery("");
                      reset({ ...getValues(), query: "" });
                    }}
                    type="button"
                    className="w-full h-full p-button-danger"
                    label="X"
                    tooltip="Hapus"
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="field col-6 md:col-3 xl:col-2">
            <label style={{ visibility: "hidden" }}>-</label>
            <Button disabled={query_value?.length < 4} label="Cari" className="p-button-success" loading={isLoading} />
          </div>
          <div className="field col-6 md:col-3 xl:col-2">
            <label style={{ visibility: "hidden" }}>-</label>
            <Button
              onClick={() => {
                reset({});
                setQuery(null);
              }}
              type="button"
              label="Hapus Filter"
              className="p-button-danger"
            />
          </div>
        </form>
      </div>
    );
  };

  const pagginatorTemplate = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    PrevPageLink: (options) => {
      const prevHandler = () => {
        setCurrentPage((prev) => prev - 1);
      };
      return (
        <button
          type="button"
          className={classNames(
            {
              "p-disabled": currentPage <= 1,
            },
            "p-paginator-next p-paginator-element p-link"
          )}
          onClick={prevHandler}
          disabled={currentPage === 1}
        >
          <span className="p-3">Sebelumnya</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      const nextHandler = () => {
        setCurrentPage((prev) => prev + 1);
      };

      return (
        <button
          type="button"
          className={classNames(
            {
              "p-disabled": currentPage === Math.ceil(totalData / limit),
            },
            options.className
          )}
          disabled={currentPage === Math.ceil(totalData / limit)}
          onClick={nextHandler}
        >
          <span className="p-3">Selanjutnya</span>
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={pageOption}
            onChange={(e) => {
              setCurrentPage(1);
              setLimit(e.value);
            }}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: "var(--text-color)", userSelect: "none", width: "120px", textAlign: "center" }}>
          {currentPage} - {Math.ceil(totalData / limit)} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={
                <div className="flex flex-column md:flex-row" style={{ gap: "1rem" }}>
                  {isSuperAdmin ? <Button onClick={csvAdminHandler} label="Download Internal File" className="p-button-success" loading={loadingExportCSV} /> : null}
                  <Button onClick={csvHandler} label={`Download ${selectItems?.length ? selectItems?.length : "All"} CSV`} className="p-button-secondary" loading={loadingExportCSV} />
                  <Button disabled={!selectItems.length} onClick={() => setSelectItems([])} label="Bersihkan" icon="fas fa-solid fa-trash-can" className="p-button-danger" loading={loadingExportCSV} />
                </div>
              }
            ></Toolbar>
            <DataTable
              selectionPageOnly={true}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              loading={isLoading}
              value={orderList}
              dataKey="_id"
              rows={pageOption}
              rowsPerPageOptions={pageOption}
              className="datatable-responsive "
              emptyMessage="Riwayat order tidak ditemukan"
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column style={{ minWidth: "12rem" }} field="created_at" header="Tanggal Order" body={(fields) => TimeFormatter(fields.created_at, "YY-M-D h:mm:ss")}></Column>
              <Column style={{ minWidth: "12rem" }} field="pickup_date" header="Tanggal Pickup" body={(fields) => TimeFormatter(fields.pickup_date, "YY-M-D h:mm:ss")}></Column>
              <Column style={{ minWidth: "12rem" }} field="order_id" header="No Order" body={(fields) => (fields.order_id ? fields.order_id : "-")}></Column>
              <Column style={{ minWidth: "12rem" }} field="resi" header="No Resi"></Column>
              <Column style={{ minWidth: "8rem" }} className="uppercase" field="courier_id.name" header="Kurir"></Column>
              <Column style={{ minWidth: "15rem" }} field="shipper_info.shipper_city" header="Kota"></Column>
              <Column style={{ minWidth: "12rem" }} field="shipper_info.shipper_district" header="Kecamatan"></Column>
              <Column style={{ minWidth: "12rem" }} field="shipper_info.shipper_zip" header="Kode Pos"></Column>
              <Column style={{ minWidth: "12rem" }} field="receiver_info.receiver_name" header="Nama Penerima"></Column>
              <Column style={{ minWidth: "12rem" }} field="receiver_info.receiver_phone" header="No Tlp Penerima"></Column>
              <Column style={{ minWidth: "12rem" }} field="receiver_info.receiver_city" header="Kota Tujuan"></Column>
              <Column style={{ minWidth: "12rem" }} field="receiver_info.receiver_district" header="Kecamatan Tujuan"></Column>
              <Column style={{ minWidth: "12rem" }} field="receiver_info.receiver_address" header="Alamat Penerima"></Column>
              <Column style={{ minWidth: "12rem" }} field="item_name" header="Nama Produk"></Column>
              <Column style={{ minWidth: "12rem" }} field="cod_price" body={(fields) => (fields.cod_price ? formatRupiah(fields.cod_price) : "-")} header="Nilai COD"></Column>
              <Column style={{ minWidth: "12rem" }} field="estimated_price.shipping_cost" body={(fields) => (fields?.estimated_price?.shipping_cost ? formatRupiah(fields.estimated_price?.shipping_cost) : "-")} header="Ongkos Kirim"></Column>
              <Column style={{ minWidth: "12rem" }} field="created_by.name" body={(fields) => (fields?.created_by?.name ? fields.created_by.name : "-")} header="Dibuat Oleh"></Column>
              <Column style={{ minWidth: "11rem" }} field="status" header="Status" body={statusField}></Column>
            </DataTable>
            <Paginator template={pagginatorTemplate} first={1} rows={limit} totalRecords={totalData}></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Report, comparisonFn);
