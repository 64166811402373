// hooks
import React from "react";

// components
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import QR from "./QR";

// libs
import JsBarcode from "jsbarcode";

// assets
import LogoBlack from "../../assets/images/logo_with_text.png";
import JNELogo from "../../assets/images/JNE_GRAY.png";
import SAPLogo from "../../assets/images/SAP_GRAY.png";
import SICEPATLogo from "../../assets/images/SICEPAT_GRAY.png";
import formatRupiah from "../../utils/formatRupiah";

const couriers = [
  { name: "jne", logo: JNELogo },
  { name: "sicepat", logo: SICEPATLogo },
  { name: "sap", logo: SAPLogo },
];

const PDF = ({ pdfData }) => {
  const getCourierLogo = (name) => {
    const courier = couriers.find((courier) => courier.name.toLowerCase() === name.toLowerCase());
    return courier.logo;
  };

  // will retun blob img
  const getBarcode = (data) => {
    let canvas;
    canvas = document.createElement("canvas");
    JsBarcode(canvas, data, {
      displayValue: false,
    });
    const barcode = canvas.toDataURL();
    return barcode;
  };
  return (
    <Document>
      {pdfData
        ? pdfData.map((item, index) => (
            <Page key={index} size="A6" style={{ padding: "20px", fontSize: "8px" }}>
              <View style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", paddingBottom: "8px" }}>
                <Image style={{ width: "40px" }} src={LogoBlack} />
                <Text style={{ fontSize: "14px" }}>{item.cod ? "COD" : "NON-COD"}</Text>
                <Image style={{ width: "40px" }} src={getCourierLogo(item.courier_id.name)} />
              </View>

              <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View style={{ width: "50%" }}>
                  <QR value={item.order_id} />
                  <Text>{item.order_id}</Text>
                </View>

                <View style={{ width: "50%", display: "flex", textAlign: "center", flexShrink: "1" }}>
                  <View>
                    <Text style={{ paddingBottom: "7px" }}>
                      {item.courier_id.name} - {item.service_type_name}
                    </Text>
                    <Text>{item.item_weight} kg</Text>
                  </View>
                  {item.insurance ? (
                    <View>
                      <Text style={{ paddingBottom: "2px", marginTop: "5px" }}>Asuransi</Text>
                      <Text>YA</Text>
                    </View>
                  ) : null}
                  {/* {item.insurance_price > 0 ? (
                    <View>
                      <Text style={{ paddingBottom: "2px", marginTop: "5px" }}>Asuransi</Text>
                      {item.insurance ? <Text>{formatRupiah(item.insurance_price)}</Text> : <Text>{formatRupiah(0)}</Text>}
                    </View>
                  ) : null} */}
                </View>
              </View>
              <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View style={{ width: "60%" }}>
                  <View style={{ paddingBottom: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Text>PENGIRIM :</Text>
                    <Text style={{ paddingRight: "5px" }}>{item.shipper_info.shipper_code}</Text>
                  </View>
                  <Text>{item.shipper_info.shipper_name}</Text>
                </View>
                {item.cod && (
                  <View style={{ width: "40%", textAlign: "center", borderLeft: "1px solid black" }}>
                    <Text style={{ fontWeight: "bold", paddingBottom: "2px" }}>COD</Text>
                    <Text style={{ paddingBottom: "7px" }}>{formatRupiah(item.cod_price)}</Text>
                  </View>
                )}
              </View>
              <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View>
                  <View style={{ paddingBottom: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ width: "50%" }}>PENERIMA :</Text>
                    <Text style={{ textAlign: "right", width: "50%" }}>{item.receiver_info.receiver_code}</Text>
                  </View>
                  <Text style={{ paddingBottom: "5px" }}>{item.receiver_info.receiver_name}</Text>
                  <Text style={{ paddingBottom: "5px" }}>{item.receiver_info.receiver_address}</Text>
                  <Text style={{ paddingBottom: "5px" }}>
                    {item.receiver_info.receiver_province} {item.receiver_info.receiver_city} {item.receiver_info.receiver_district} {item.receiver_info.receiver_urban} {item.receiver_info.receiver_zip}
                  </Text>
                  <Text>{item.receiver_info.receiver_phone}</Text>
                </View>
              </View>
              <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View>
                  <Text style={{ paddingBottom: "5px" }}>BARANG :</Text>
                  <Text style={{ paddingBottom: "5px" }}>
                    {item.item_qty}x - {item.item_name}
                  </Text>
                </View>
              </View>
              <View style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "10px 0" }}>
                <View style={{ display: "flex", transform: "translateX(65% 0)" }}>
                  <Image style={{ width: "130px", height: "50px" }} src={getBarcode(item.resi)} />
                </View>
                <Text style={{ textAlign: "center" }}>{item.resi}</Text>
              </View>
            </Page>
          ))
        : ""}
    </Document>
  );
};

export default PDF;
