// hooks
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";

// utils
import TimeFormatter from "../utils/TimeFormatter";
import Api from "../api/Api";
import classNames from "classnames";

const CourierService = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // api calling
  const { data: courierServicesData, isLoading, refetch } = useQuery("couriers-services", async () => await getCourierServices(), { defaultValue: [] });
  const { data: courierData, isLoading: loadingCourier } = useQuery("couriers", async () => await getCouriers(), { defaultValue: [] });

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/service-type", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowCreateModal(false);
        reset({});
        toast.success("Courier Service Created!", { duration: 4000 });
      } else {
        refetch();
        setShowCreateModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/service-type/edit", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowEditModal(false);
        toast.success("Courier Service Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/service-type", { data: data }), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Courier Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const { handleSubmit, control, formState, reset } = useForm();
  const updateForm = useForm();

  // functions
  const getCourierServices = async () => {
    const response = await Api().get("/service-type");
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data.data;
  };

  const getCouriers = async () => {
    const response = await Api().get("courier");
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data.data;
  };

  const create = (data) => {
    crateMutate(data);
  };

  const update = (data) => {
    delete data.address;
    updateMutate({ ...data, service_type_id: data._id });
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      service_type_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.service_type_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      service_type_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editHandler = async (data) => {
    updateForm.reset({ ...data, courier_id: data.courier_id._id });
    setShowEditModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => setShowCreateModal(true)} />}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Courier Service</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  const FormCourierServices = ({ control }) => {
    return (
      <>
        <div className="field">
          <label htmlFor="name">Nama Layanan : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="name"
            render={({ field }) => <InputText placeholder="Masukkan nama layanan" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors?.name && "p-invalid"} />}
          />
          {formState.errors?.name && (
            <small id="name" className="p-error block pt-1">
              Masukkan nama layanan
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="name">Nama Tampilan Layanan : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="display_name"
            render={({ field }) => <InputText placeholder="Masukkan nama tampilan layanan" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors?.display_name && "p-invalid"} />}
          />
          {formState.errors?.display_name && (
            <small id="name" className="p-error block pt-1">
              Masukkan nama tampilan layanan
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="name">Nama Alias Layanan : </label>
          <Controller
            rules={{ required: false }}
            control={control}
            defaultValue={""}
            name="alias_name"
            render={({ field }) => <InputText placeholder="Masukkan nama Alias layanan" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors?.alias_name && "p-invalid"} />}
          />
          {formState.errors?.alias_name && (
            <small id="name" className="p-error block pt-1">
              Masukkan nama Alias layanan
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="name">Deskripsi Layanan : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="description"
            defaultValue={""}
            render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={formState.errors?.description && "p-invalid"} placeholder="Masukkan deskripsi" autoResize rows="3" cols="20" />}
          />
          {formState.errors?.description && (
            <small id="description" className="p-error block pt-1">
              Masukkan deskripsi
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="name">Pilih Kurir : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="courier_id"
            render={({ field }) => (
              <Dropdown
                ref={field.ref}
                optionLabel="name"
                optionValue="_id"
                disabled={!courierData.length}
                value={field.value}
                onBlur={field.onBlur}
                options={courierData}
                className={classNames({
                  "p-invalid": formState.errors.courier_id,
                })}
                onChange={(e) => {
                  field.onChange(e);
                }}
                placeholder={loadingCourier ? "loading..." : !loadingCourier && !courierData.length ? "Kurir Tidak Ada" : "Pilih Kurir"}
              />
            )}
          />
          {formState.errors.courier_id && (
            <small id="choose-kurir" className="p-error block pt-1">
              Anda belum memilih kurir
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="discount_percentage">Persentase Diskon (%): </label>
          <Controller
            control={control}
            name="discount_percentage"
            defaultValue={0}
            render={({ field }) => (
              <InputNumber
                minFractionDigits={2}
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                mode="decimal"
                placeholder="Masukkan persentase diskon"
                className={classNames({
                  "p-invalid": formState.errors.discount_percentage,
                })}
              />
            )}
          />
          {formState.errors.discount_percentage && (
            <small id="discount_percentage" className="p-error block pt-1">
              Masukkan persentase diskon
            </small>
          )}
        </div>
        <div className="field">
          <label className="block" htmlFor="active_status">
            Active status
          </label>
          <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
        </div>
      </>
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        optionLabel="name"
        optionValue="value"
        options={[
          { name: "Aktif", value: true },
          { name: "Tidak aktf", value: false },
        ]}
        value={options.value}
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        showClear
        placeholder="Pilih Status"
      />
    );
  };

  const courierRowFilterTemplate = (options) => {
    return (
      <Dropdown
        disabled={!courierData?.length}
        optionLabel="name"
        optionValue="name"
        options={courierData}
        value={options.value}
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        showClear
        placeholder="Pilih Kurir"
      />
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12 mx-auto">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={courierServicesData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              globalFilterFields={["courier_id.name", "active_status"]}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Layanan Kurir Tidak Tersedia"
              header={header}
              responsiveLayout="scroll"
              filterDisplay="row"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="courier_id.name" showFilterMenu={false} filterMatchMode="contains" filterField="courier_id.name" filter filterElement={courierRowFilterTemplate} header="Kurir" sortable headerStyle={{ width: "auto", minWidth: "6rem" }}></Column>
              <Column field="display_name" showFilterMenu={false} filter filterPlaceholder="Cari layanan" header="Nama Layanan" sortable headerStyle={{ width: "auto", minWidth: "6rem" }}></Column>
              <Column
                filterMatchMode="equals"
                field="active_status"
                showFilterMenu={false}
                filter
                filterField="active_status"
                filterElement={statusRowFilterTemplate}
                body={(field) => (field.active_status ? "Aktif" : "Tidak Aktif")}
                header="Status"
                sortable
                headerStyle={{ width: "auto", minWidth: "4rem" }}
              ></Column>
              <Column body={(field) => (field.discount_percentage ? field.discount_percentage : 0 + "%")} field="discount_percentage" header="Diskon" sortable headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column body={(field) => TimeFormatter(field.created_at, "ll")} field="created_at" header="Dibuat" sortable headerStyle={{ width: "15%", minWidth: "5rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "auto", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} header="Create Kurir Service" modal className="p-fluid modal-container" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <FormCourierServices disabledService control={control} />
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} header="Edit Kurir Service" modal className="p-fluid modal-container" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <FormCourierServices control={updateForm.control} />
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Kurir?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(CourierService, comparisonFn);
