import classNames from "classnames";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import Api from "../../api/Api";
import useUserStore from "../../stores/userStore";
import CreateAddressModal from "../user/CreateAddressModal";

const FormAddress = ({ control, formState, fieldKey, weight, getValues, reset }) => {
  const userStore = useUserStore((state) => state);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [address, setAddress] = useState([]);

  const { isLoading: loadingGetAddress, mutate: getUserAddress } = useMutation(async (data) => await Api().post("/user/addresses", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }

      setAddress(response.data.data);
    },
  });

  useEffect(() => {
    getUserAddress({
      user_id: userStore.user._id,
    });
  }, [userStore.user._id, getUserAddress]);

  const EmptyMessage = () => {
    return (
      <div className="flex">
        <Button onClick={() => setShowModalAddress(true)} className="p-button-success" label="tambah alamat+" />
      </div>
    );
  };

  return (
    <>
      {weight && (
        <div className={"field col-12"}>
          <label htmlFor="basic">Berat (KG) :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={1}
            name="weight"
            render={({ field }) => (
              <InputNumber
                ref={field.ref}
                value={field.value}
                className={classNames({
                  "p-invalid": formState.errors.weight,
                })}
                onValueChange={(e) => field.onChange(e)}
                mode="decimal"
                min={1}
                max={100}
              />
            )}
          />
          {formState.errors.weight && (
            <small id="choose-origin" className="p-error block pt-1">
              Silahkan masukkan berat dalam satuan KG
            </small>
          )}
        </div>
      )}

      <div className="field col-12 md:col-12">
        <label htmlFor="choose-kurir">Pilih Alamat :</label>
        <Dropdown
          filter
          emptyFilterMessage="data tidak ditemukan"
          filterBy="name"
          optionLabel="name"
          emptyMessage={EmptyMessage}
          disabled={loadingGetAddress}
          options={address}
          value={selectedAddress}
          onChange={async (e) => {
            setSelectedAddress(e.value);
            let current_value = getValues();
            let data = {};
            data[fieldKey] = e.value;
            reset({ ...current_value, ...data });
          }}
          placeholder={loadingGetAddress ? "loading..." : "Pilih Alamat"}
        />
      </div>

      {Object.keys(selectedAddress).length ? (
        <div className="field col-12 md:col-12 ">
          <div className="p-4 user-destination-wrapper">
            <h6 className="font-bold">
              {selectedAddress.name}, {selectedAddress.phone_number}
            </h6>
            <p>
              {selectedAddress.address} {selectedAddress.province} {selectedAddress.district} {selectedAddress.city} {selectedAddress.postal_code}
            </p>
          </div>
        </div>
      ) : null}

      <CreateAddressModal
        show={showModalAddress}
        setShow={setShowModalAddress}
        refetch={() =>
          getUserAddress({
            user_id: userStore.user._id,
          })
        }
      />
    </>
  );
};

export default FormAddress;
