// hooks
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import CreateAddressModal from "../../components/user/CreateAddressModal";
import UpdateAddressModal from "../../components/user/UpdateAddressModal";

// utils
import ActiveStatus from "../../utils/activeStatus";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import Api from "../../api/Api";

const UserAddress = ({ permissions }) => {
  const token = Cookies.get("token");
  const user = jwtDecode(token);

  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [address, setAddress] = useState([]);
  const [tempUserAddress, setTempUserAddress] = useState({});

  // api calling
  const { isLoading: loadingGetAddress, mutate: getUserAddress } = useMutation(async (data) => await Api().post("/user/addresses", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }

      setAddress(response.data.data);
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/user/address", { data: data }), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        getUserAddress({ user_id: user._id });
        setShowDeleteItemModal(false);
        toast.success("Alamat Telah Dihapus", { duration: 4000 });
      } else {
        setSelectItems([]);
        getUserAddress({ user_id: user._id });
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const showNewModal = () => {
    setShowCreateModal(true);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems({
      name: data.name,
      address_id: data._id,
      user_id: user._id,
    });
    setShowDeleteItemModal(true);
  };

  const confirmDeleteItem = () => {
    let payload = {
      address_id: selectItems.address_id,
      user_id: user._id,
    };

    deleteMutate(payload);
  };

  const editHandler = async (data) => {
    setTempUserAddress(data);
    setShowEditModal(true);
  };

  // life cycle
  useEffect(() => {
    getUserAddress({
      user_id: user._id,
    });
  }, [user._id, getUserAddress]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">{permissions.create && <Button label="Buat Alamat" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewModal} />}</div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    let status = rowData.active_status ? "instock" : "outofstock";
    return <span className={`product-badge status-${status}`}>{ActiveStatus(rowData.active_status)}</span>;
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Pengaturan Alamat</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={loadingGetAddress}
              value={address}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Menampilkan {first} ke {last} dari {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Anda belum memiliki alamat yang tersimpan."
              header={header}
              responsiveLayout="scroll"
            >
              <Column field="name" header="Nama Toko" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="city" header="Kota" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="postal_code" header="Kode Pos" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column body={statusBodyTemplate} field="active_status" header="Status" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <CreateAddressModal
        show={showCreateModal}
        setShow={setShowCreateModal}
        refetch={() =>
          getUserAddress({
            user_id: user._id,
          })
        }
      />

      <UpdateAddressModal
        show={showEditModal}
        setShow={setShowEditModal}
        value={tempUserAddress}
        refetch={() =>
          getUserAddress({
            user_id: user._id,
          })
        }
      />

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center  mb-4">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          <span>
            Are you sure you want to delete <b>{selectItems?.name}</b>?
          </span>
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(UserAddress, comparisonFn);
