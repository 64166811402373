// hooks
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import TimeFormatter from "../../utils/TimeFormatter";
import AddressFieldOrderDetails from "../../components/order/AddressFieldOrderDetails";
import PriceEstimationForm from "../../components/requestpickup/PriceEstimationForm";

// api related
import Api from "../../api/Api";
import { InputNumber } from "primereact/inputnumber";

const DetailsOrderHistory = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // hooks
  const { control, reset } = useForm();
  const [activeIndex, setActiveIndex] = useState(0);

  // api calling
  const { isError: isRoleDetailsError, data: detailsOrderData } = useQuery(["order", id], async () => getDetails());

  // functions
  const getDetails = async () => {
    const response = await Api().get(`/order/${id}`);
    if (response.data.status !== 200) {
      throw new Error(response.data.message);
    }
    reset(response.data.data);
    return response.data.data;
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h5 className="uppercase " style={{ margin: 0 }}>
            DETAIL RIWAYAT ORDER
          </h5>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <Link to="/dashboard/order-history">
          <Button label="Kembali" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  // lifecycle

  useEffect(() => {
    if (isRoleDetailsError) {
      navigate("/dashboard/role");
    }
  }, [isRoleDetailsError, navigate]);

  return (
    <form style={{ borderRadius: 0 }} className="card col-11 mx-auto non-input">
      <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Detail Pesanan">
          <div className="col-12">
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-12">
                <p className="font-bold uppercase">Detail Kurir</p>
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="user">No Resi:</label>
                <Controller control={control} name="resi" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="user">Waktu Pickup:</label>
                <Controller control={control} name="pickup_date" render={({ field }) => <InputText value={TimeFormatter(field.value, "lll")} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="user">Kurir:</label>
                <Controller control={control} name="courier_id.name" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="user">Layanan:</label>
                <Controller control={control} name="service_type" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>

              <div className="field col-12 md:col-12 mt-4">
                <p className="font-bold uppercase">Detail Barang</p>
              </div>
              <div className="field col-12 md:col-12 grid">
                <div style={{ gap: "5px" }} className=" col-12 md:col-2 flex align-items-center">
                  <Controller
                    control={control}
                    name="cod"
                    render={({ field }) => (
                      <Checkbox
                        disabled
                        inputId="cod"
                        onChange={(e) => {
                          field.onChange(e.checked);
                        }}
                        name="cod"
                        checked={field.value}
                      />
                    )}
                  />
                  <label htmlFor="cb1" className="p-checkbox-label">
                    COD
                  </label>
                </div>
                <div style={{ gap: "5px" }} className=" col-12 md:col-2 flex align-items-center mt-2 md:mt-0">
                  <Controller
                    control={control}
                    name="insurance"
                    render={({ field }) => (
                      <Checkbox
                        disabled
                        inputId="cod"
                        onChange={(e) => {
                          field.onChange(e.checked);
                        }}
                        name="insurance"
                        checked={field.value}
                      />
                    )}
                  />
                  <label htmlFor="cb1" className="p-checkbox-label">
                    Asuransi
                  </label>
                </div>
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Nama Produk:</label>
                <Controller control={control} name="item_name" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Kategori Produk:</label>
                <Controller control={control} name="item_category" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Berat: (kg)</label>
                <Controller control={control} name="item_weight" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" />} />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Harga:</label>
                <Controller control={control} name="item_price" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" />} />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Harga COD:</label>
                <Controller control={control} name="cod_price" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" />} />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Harga Asuransi:</label>
                <Controller control={control} name="insurance_price" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" />} />
              </div>

              <PriceEstimationForm data={detailsOrderData?.estimated_price} title="BIAYA KIRIM" />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Detail Alamat">
          <div className="col-12">
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-12">
                <p className="font-bold uppercase">Alamat Pengirim</p>
              </div>
              <AddressFieldOrderDetails data={detailsOrderData} preFixKey="shipper_info" preFix="shipper" control={control} />
              <div className="field col-12 md:col-12 mt-4">
                <p className="font-bold uppercase">Alamat Penerima</p>
              </div>
              <AddressFieldOrderDetails data={detailsOrderData} preFixKey="receiver_info" preFix="receiver" control={control} />
            </div>
          </div>
        </TabPanel>
      </TabView>

      <div className="flex justify-content-center mt-4">
        <Link to="/dashboard/order-history">
          <Button type="button" label="Kembali" className=" p-button-secondary" />
        </Link>
      </div>
    </form>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(DetailsOrderHistory, comparisonFn);
