// hooks
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";

// utils
import Api from "../api/Api";
import { toast } from "react-hot-toast";
import classNames from "classnames";

// childs components
const Header = () => {
  return (
    <div className="col-12">
      <div>
        <Toolbar
          className="mb-4 py-4"
          left={() => (
            <div className="">
              <h1 className="text-xl uppercase p-0 m-0">Form Informasi Bank</h1>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const AlertAlreadyHasBank = ({ disableFields }) => {
  return (
    <div
      className={classNames(
        {
          hidden: !disableFields,
        },
        "field col-12"
      )}
    >
      <p style={{ color: "red" }}>*Untuk melakukan pergantian akun bank silahkan hubungi admin</p>
    </div>
  );
};

const FormFields = ({ control, disableFields, formState }) => {
  return (
    <>
      <div className="field col-12">
        <label htmlFor="bank_account_number">Nomor Rekening : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="bank_account_number"
          render={({ field }) => (
            <InputText
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              disabled={disableFields}
              placeholder="Masukkan nomor rekening"
              value={field.value}
              onBlur={field.onBlur}
              ref={field.ref}
              onChange={(e) => field.onChange(e)}
              id="bank_account_number"
              type="text"
              className={formState.errors.bank_account_number && "p-invalid"}
            />
          )}
        />
        {formState.errors.bank_account_number && (
          <small id="bank_account_number" className="p-error block pt-1">
            Masukkan nomor rekening
          </small>
        )}
      </div>
      <div className="field col-12">
        <label htmlFor="bank_user_name">
          Nama Penerima{" "}
          <span
            className={classNames(
              {
                hidden: disableFields,
              },
              "text-sm"
            )}
            style={{ color: "red" }}
          >
            (Nama Penerima Wajib Sama Dengan Akun Bank)
          </span>
          :
        </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="bank_user_name"
          render={({ field }) => <InputText disabled={disableFields} placeholder="Masukkan nama penerima" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="bank_user_name" type="text" className={formState.errors.bank_user_name && "p-invalid"} />}
        />
        {formState.errors.bank_user_name && (
          <small id="bank_user_name" className="p-error block pt-1">
            Masukkan nama penerima
          </small>
        )}
      </div>
      <div className="field col-12">
        <label htmlFor="bank_name">Nama Bank : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="bank_name"
          render={({ field }) => <InputText disabled={disableFields} placeholder="Contoh BCA , Mandiri ..." value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="bank_name" type="text" className={formState.errors.bank_name && "p-invalid"} />}
        />
        {formState.errors.bank_name && (
          <small id="bank_name" className="p-error block pt-1">
            Masukkan nomor rekening
          </small>
        )}
      </div>
    </>
  );
};

const MyBank = () => {
  // hooks
  const { control, formState, handleSubmit, reset } = useForm();
  const [disableFields, setDisableFields] = useState(true);

  // functions
  const checkBankInfo = async () => {
    try {
      const res = await Api().get("/user/check-user-bank");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      if (res.data.data.exists_bank_info) {
        reset(res.data.data);
      }

      // disable or enable formstate
      setDisableFields(res.data.data.exists_bank_info);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };
  const submit = (data) => {
    saveBankInformation(data);
  };

  // react query
  const { refetch } = useQuery("bank-info", checkBankInfo, { initialData: {} });
  const { mutate: saveBankInformation, isLoading } = useMutation(async (data) => await Api().post("/user/update-bank-info", data), {
    onSettled: (response, err) => {
      if (err) return toast.error("gagal menyimpan informasi bank");
      if (response.data.status !== 200) return toast.error(response.data.message);
      toast.success("Berhasil menyimpan informasi bank");
      refetch();
    },
  });

  return (
    <div className="p-fluid formgrid grid card col-12 mx-auto">
      <Header />
      <div className="col-12">
        <form onSubmit={handleSubmit(submit)} className="p-fluid grid formgrid mt-2">
          <AlertAlreadyHasBank disableFields={disableFields} />
          <FormFields control={control} formState={formState} disableFields={disableFields} />
          {!disableFields ? (
            <div className="flex justify-content-center mt-5 col-12 gap-1">
              <div className="mr-4">
                <Button label="Simpan" className="p-button-success" loading={isLoading} />
              </div>
              <div className="">
                <Link to="/dashboard">
                  <Button type="button" label="Kembali" className="p-button-secondary" />
                </Link>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default MyBank;
