import classNames from "classnames";
import React from "react";
import formatRupiah from "../utils/formatRupiah";
import { Skeleton } from "primereact/skeleton";

const BalanceCardSkleton = ({ className }) => (
  <div className={classNames(className)}>
    <div className="card shadow-lg flex justify-content-between mb-3">
      <div>
        <span className="block text-500 font-medium mb-3 uppercase">
          <Skeleton width="200px" />
        </span>
        <div className="text-900 font-medium text-lg">
          <span className="text-green-500 font-medium">
            <span className="text-500 flex">
              <Skeleton width="80px" />
            </span>
          </span>
        </div>
      </div>
      <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
        <Skeleton width="2.5rem" height="2.5rem" borderRadius="20px" />
      </div>
    </div>
  </div>
);

const BalanceCard = ({ title, balance = 0, className, icon, isLoading, parseToMoney = true, status = "success" }) => {
  return (
    <>
      {isLoading ? (
        <BalanceCardSkleton className={className} />
      ) : (
        <div className={classNames(className)}>
          <div className="card shadow-lg flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3 uppercase">{title}</span>
              <div className="text-900 font-medium text-lg">
                <span className="text-green-500 font-medium">{parseToMoney ? <span className="text-500 flex">{formatRupiah(balance)}</span> : <span className="text-500 flex">{balance}</span>}</span>
              </div>
            </div>
            <div
              className={classNames(
                {
                  "bg-green-100": status === "success",
                  "bg-red--100": status === "danger",
                  "bg-yellow-100": status === "warning",
                },
                "flex align-items-center justify-content-center"
              )}
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i
                className={classNames(icon, "fas fa-solid", {
                  "text-green-500": status === "success",
                  "text-red--100": status === "danger",
                  "text-yellow-500": status === "warning",
                })}
              ></i>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BalanceCard;
