// hooks
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

// components
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";

// utils
import classNames from "classnames";
import convertToIdr from "../../utils/convertToIdr";
import { TabPanel, TabView } from "primereact/tabview";
import FormAddress from "../../components/checkprice/FormAddress";
import { useMutation } from "react-query";
import Api from "../../api/Api";
import toast from "react-hot-toast";
import { AutoComplete } from "primereact/autocomplete";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

const CheckPrice = () => {
  const { control, handleSubmit, formState, getValues, reset, watch } = useForm();
  const [results, setResults] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [userDestinationData, setUserDestinationData] = useState({});
  const [filterAddress, setFilterAddress] = useState([]);
  const [isManual, setIsManual] = useState(true);

  // watch
  const destination_address_value = watch("destination_address");
  const origin_address_value = watch("origin_address");

  // react query
  const { mutate: getPrice, isLoading } = useMutation(async (data) => await Api().post("/courier/all-prices", data), {
    onSettled: (response, err) => {
      if (err) return toast.error("gagal mendapatkan harga silahkan coba lagi");
      if (response.data.status !== 200) return toast.error(response.data.message);
      if (!response.data.data.length) return toast.error("Alamat yang dimasukan tidak support layanan apapun");

      reset({});
      setResults(response.data.data);
      setActiveIndex(1);
    },
  });

  const { mutate: getAddress } = useMutation(async (data) => await Api().post("/address/autofill-code", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }
      setFilterAddress(response.data.data);
    },
  });

  // functions
  const submitHandler = async (data) => {
    setUserDestinationData(data);
    getPrice(data);
  };

  const searchItemDestination = (event) => {
    if (event.query.length > 3) {
      getAddress({
        text: event.query,
        type: "destination",
        for_address: true,
      });
    }
  };

  const searchItemOrigin = (event) => {
    if (event.query.length > 3) {
      getAddress({
        text: event.query,
        type: "origin",
        for_address: true,
      });
    }
  };

  return (
    <div className="p-fluid formgrid grid card col-12 xl:col-11 mx-auto">
      <Toolbar
        className="mb-4 py-4 col-12"
        left={() => (
          <div className="px-2">
            <h1 className="text-xl uppercase p-0 m-0">Form Cek Harga</h1>
          </div>
        )}
      />

      <div className="col-12">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Form Cek Harga">
            <form onSubmit={handleSubmit(submitHandler)} className="p-fluid grid formgrid mt-2">
              {/* form */}
              <div className={`col-12  md:col-12 flex justify-items-center`}>
                <h6 className="font-bold">Tujuan Awal</h6>
              </div>

              {!isManual ? (
                <>
                  <div className="field col-12">
                    <label htmlFor="basic">Berat (KG) :</label>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue={1}
                      name="weight"
                      render={({ field }) => (
                        <InputNumber
                          ref={field.ref}
                          value={field.value}
                          className={classNames({
                            "p-invalid": formState.errors.weight,
                          })}
                          onValueChange={(e) => field.onChange(e)}
                          mode="decimal"
                          min={1}
                          max={100}
                        />
                      )}
                    />
                    {formState.errors.weight && (
                      <small id="choose-origin" className="p-error block pt-1">
                        Silahkan masukkan berat dalam satuan KG
                      </small>
                    )}
                  </div>
                  <div className="field col-12">
                    <label htmlFor="origin_address">Kecamatan / Kota / Provinsi : </label>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name="origin_address"
                      defaultValue={""}
                      id="origin_address"
                      render={({ field }) => (
                        <div className="relative w-full">
                          <AutoComplete
                            id="destination-autocomplete"
                            forceSelection
                            value={field.value}
                            onBlur={field.onBlur}
                            suggestions={filterAddress}
                            completeMethod={searchItemOrigin}
                            className={formState.errors?.origin_address && "p-invalid"}
                            field="full_name"
                            ref={field.ref}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            placeholder="Masukkan huruf minimal 4"
                          />
                          <div style={{ right: "15px" }} className="absolute top-0 text-center flex">
                            {origin_address_value && <Button onClick={() => reset({ ...getValues(), origin_address: "" })} type="button" className="w-full h-full p-button-danger p-button-text" icon="pi pi-trash" tooltip="Hapus" />}
                          </div>
                        </div>
                      )}
                    />
                    {formState.errors?.origin_address && (
                      <small id="origin_address" className="p-error block pt-1">
                        Anda belum memilih alamat
                      </small>
                    )}
                  </div>
                </>
              ) : (
                <FormAddress watch={watch} control={control} formState={formState} getValues={getValues} reset={reset} weight fieldKey="origin_address" title="Tujuan Awal" />
              )}
              <div className="field col-12">
                <label htmlFor="origin_address">Gunakan Alamat Yang Tersimpan : </label>
                <InputSwitch
                  className="block"
                  checked={isManual}
                  onChange={(e) => {
                    reset({ ...getValues(), origin_address: null });
                    setIsManual(e.value);
                  }}
                />
              </div>

              <div className={`col-12 mt-2 md:col-12 flex justify-items-center`}>
                <h6 className="font-bold">Tujuan Akhir</h6>
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="destination_address">Kecamatan / Kota / Provinsi : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="destination_address"
                  defaultValue={""}
                  render={({ field }) => (
                    <div className="relative w-full">
                      <AutoComplete
                        id="destination-autocomplete"
                        forceSelection
                        value={field.value}
                        onBlur={field.onBlur}
                        suggestions={filterAddress}
                        completeMethod={searchItemDestination}
                        className={formState.errors?.destination_address && "p-invalid"}
                        field="full_name"
                        ref={field.ref}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        placeholder="Masukkan huruf minimal 4"
                      />
                      <div className="absolute top-0 text-center flex" style={{ right: "15px" }}>
                        {destination_address_value && <Button onClick={() => reset({ ...getValues(), destination_address: "" })} type="button" className="w-full h-full p-button-danger p-button-text" icon="pi pi-trash" tooltip="Hapus" />}
                      </div>
                    </div>
                  )}
                />
                {formState.errors?.destination_address && (
                  <small id="destination_address" className="p-error block pt-1">
                    Anda belum memilih alamat
                  </small>
                )}
              </div>
              <div className="flex justify-content-center mt-5 col-12 gap-1">
                <div className="mr-4">
                  <Button loading={isLoading} label="Cek Harga" className="p-button-success " />
                </div>
                <div className="">
                  <Link to="/dashboard/products">
                    <Button type="button" label="Kembali" className="p-button-secondary" />
                  </Link>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel disabled={!results.length} header="Hasil">
            {Object.keys(userDestinationData).length ? (
              <div className="grid">
                <div className="col-12 md:col-6 lg:col-12 xl:col-6">
                  <div className={classNames({ "user-destination-wrapper": results.length })}>
                    <table style={{ borderSpacing: "10px" }}>
                      <tr className="font-bold uppercase">Tujuan Awal</tr>
                      <tr>
                        <td>Provinsi</td>
                        <td>:</td>
                        <td>{userDestinationData.origin_address.province}</td>
                      </tr>
                      <tr>
                        <td>Kota</td>
                        <td>:</td>
                        <td>{userDestinationData.origin_address.city}</td>
                      </tr>
                      <tr>
                        <td>Kecamatan</td>
                        <td>:</td>
                        <td>{userDestinationData.origin_address.district}</td>
                      </tr>
                      {userDestinationData?.origin_address?.urban && (
                        <tr>
                          <td>Kelurahan</td>
                          <td>:</td>
                          <td>{userDestinationData.origin_address.urban}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Kode Pos</td>
                        <td>:</td>
                        <td>{userDestinationData.origin_address.postal_code}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-12 md:col-6 lg:col-12 xl:col-6">
                  <div className={classNames({ "user-destination-wrapper": results.length })}>
                    <table style={{ borderSpacing: "10px" }}>
                      <tr className="font-bold uppercase">Tujuan Akhir</tr>
                      <tr>
                        <td>Provinsi</td>
                        <td>:</td>
                        <td>{userDestinationData.destination_address.province}</td>
                      </tr>
                      <tr>
                        <td>Kota</td>
                        <td>:</td>
                        <td>{userDestinationData.destination_address.city}</td>
                      </tr>
                      <tr>
                        <td>Kecamatan</td>
                        <td>:</td>
                        <td>{userDestinationData.destination_address.district}</td>
                      </tr>
                      {userDestinationData?.destination_address?.urban && (
                        <tr>
                          <td>Kelurahan</td>
                          <td>:</td>
                          <td>{userDestinationData.destination_address.urban}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Kode Pos</td>
                        <td>:</td>
                        <td>{userDestinationData.destination_address.postal_code}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mt-4 col-12">
              {results.map((data, index) => (
                <div className="w-full" key={index + 5}>
                  <p className={classNames({ "mt-4": index > 0 }, "font-bold uppercase")}>{data.courier_name}</p>
                  <DataTable className="mt-4" width="100%" value={data.prices} columnResizeMode="fit" showGridlines responsiveLayout="scroll">
                    <Column field="service" header="Layanan" style={{ width: "20%" }} />
                    <Column field="description" header="Deskripsi Layanan" style={{ width: "40%" }} />
                    <Column body={(data) => convertToIdr(data.price)} field="tariff" header="Tarif" style={{ width: "20%" }} />
                    <Column field="etd" header="Estimasi" style={{ width: "20%" }} />
                  </DataTable>
                </div>
              ))}

              <div className="flex justify-content-center mt-6">
                <div className="col-6 md:col-2 lg:col-4 xl:col-2">
                  <Button
                    className="p-button-danger"
                    onClick={() => {
                      setResults([]);
                      setActiveIndex(0);
                    }}
                    label="Bersihkan Hasil"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>

      {/* tooltip */}
      <Tooltip target="#destination-autocomplete" />
      <Tooltip target="#destination-autocomplete" />
    </div>
  );
};

export default CheckPrice;
