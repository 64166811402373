import Users from "./pages/Users/Users";
import Role from "./pages/Role/Role";
import Page from "./pages/PageSetting/Page";
import CreatePage from "./pages/PageSetting/CreatePage";
import CreateRole from "./pages/Role/CreateRole";
import UpdateRole from "./pages/Role/UpdateRole";
import CheckPrice from "./pages/v2/CheckPrice";
import CheckResi from "./pages/v2/CheckResi";
import AccountSetting from "./pages/v2/AccountSetting";
import CreateOrder from "./pages/v2/CreateOrder";
import UserAddress from "./pages/v2/UserAddress";
import Couriers from "./pages/Couriers";
import OrderHistory from "./pages/OrderHistory/OrderHistory";
import DetailOrderHistory from "./pages/OrderHistory/DetailOrderHistory";
import ProductCategories from "./pages/v2/ProductCategories";
import CourierService from "./pages/CourierService";
import UpdateUser from "./pages/Users/UpdateUser";
import Report from "./pages/Report/Report";
import Balance from "./pages/v2/Balance";
import MyBank from "./pages/MyBank";
import CreateOrderMultiple from "./pages/v2/CreateOrderMultiple";
import DashboardDetails from "./pages/DashboardDetails";

const pagesList = [
  {
    route: "/dashboard/order-pickup-multiple",
    component: CreateOrderMultiple,
    childs: [],
    hasChild: false,
  },

  // dashboard/details is hidden , i hide in login file
  {
    route: "/dashboard/details",
    component: DashboardDetails,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-categories",
    component: ProductCategories,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/my-bank",
    component: MyBank,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/report",
    component: Report,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/balance",
    component: Balance,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/user-address",
    component: UserAddress,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/couriers",
    component: Couriers,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/courier-services",
    component: CourierService,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/order-pickup",
    component: CreateOrder,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/check-price",
    component: CheckPrice,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/account",
    component: AccountSetting,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/check-resi",
    component: CheckResi,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/role",
    component: Role,
    childs: [
      {
        route: "/dashboard/role/create",
        component: CreateRole,
        access: "create",
      },
      {
        route: "/dashboard/role/update/:id",
        component: UpdateRole,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/order-history",
    component: OrderHistory,
    childs: [
      {
        route: "/dashboard/order-history/details/:id",
        component: DetailOrderHistory,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/pages",
    component: Page,
    childs: [
      {
        route: "/dashboard/pages/create",
        component: CreatePage,
        access: "create",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/users",
    component: Users,
    childs: [
      {
        route: "/dashboard/users/update/:id",
        component: UpdateUser,
        access: "update",
      },
    ],
    hasChild: true,
  },
];

export default pagesList;
