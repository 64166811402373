const convertToIdr = (number = 0) => {
  var rupiah = "";
  // make sure number is  number not string
  let parseINT = parseInt(number);
  var angkarev = parseINT.toString().split("").reverse().join("");
  for (var i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + ".";
  return (
    "IDR " +
    rupiah
      .split("", rupiah.length - 1)
      .reverse()
      .join("")
  );
};

export default convertToIdr;
