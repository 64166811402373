import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TimeFormatter from "../../../utils/TimeFormatter";
import PriceEstimationForm from "../PriceEstimationForm";

export const PreviewDataMultiple = ({ previewData }) => {
  const { control, reset } = useForm();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    reset(previewData);
  }, [previewData, reset]);

  return (
    <>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Detail Pengiriman">
          <div className="p-fluid formgrid grid non-input col-12">
            <div className="field col-12 md:col-12">
              <p className="font-bold uppercase">Detail Kurir</p>
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="user">Waktu Pickup:</label>
              <Controller control={control} name="pickup_date" render={({ field }) => <InputText value={TimeFormatter(field.value, "lll")} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="user">Kurir:</label>
              <Controller control={control} name="courier_name" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="user">Layanan:</label>
              <Controller control={control} name="service_type" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>

            <div className="field col-12 md:col-12 mt-4">
              <p className="font-bold uppercase">Detail Barang</p>
            </div>
            <div className="field col-12 md:col-12 grid">
              <div style={{ gap: "5px" }} className=" col-6 md:col-2 flex align-items-center">
                <Controller
                  control={control}
                  name="cod"
                  render={({ field }) => (
                    <Checkbox
                      disabled
                      inputId="cod"
                      onChange={(e) => {
                        field.onChange(e.checked);
                      }}
                      name="cod"
                      checked={field.value}
                    />
                  )}
                />
                <label htmlFor="cb1" className="p-checkbox-label">
                  COD
                </label>
              </div>
              <div style={{ gap: "5px" }} className=" col-6 md:col-2 flex align-items-center">
                <Controller
                  control={control}
                  name="insurance"
                  render={({ field }) => (
                    <Checkbox
                      disabled
                      inputId="cod"
                      onChange={(e) => {
                        field.onChange(e.checked);
                      }}
                      name="insurance"
                      checked={field.value}
                    />
                  )}
                />
                <label htmlFor="cb1" className="p-checkbox-label">
                  Asuransi
                </label>
              </div>
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Nama Produk:</label>
              <Controller control={control} name="item_name" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Kategori Produk:</label>
              <Controller control={control} name="item_category" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Harga:</label>
              <Controller defaultValue={0} control={control} name="item_price" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Harga COD:</label>
              <Controller defaultValue={0} control={control} name="cod_price" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Harga Asuransi:</label>
              <Controller defaultValue={0} control={control} name="insurance_price" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="Masukkan harga barang" />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Berat: (kg)</label>
              <Controller control={control} name="item_weight" render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>

            <PriceEstimationForm data={previewData?.estimated_price} title="BIAYA KIRIM" />
          </div>
        </TabPanel>
        <TabPanel header="Alamat">
          <div className="p-fluid formgrid grid non-input">
            <div className="field col-12 md:col-12 ">
              <p className="font-bold uppercase">Alamat Pengirim</p>
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="user">Nama :</label>
              <Controller control={control} name={`shipper_info.shipper_name`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="user">E-Mail :</label>
              <Controller control={control} name={`shipper_info.shipper_email`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="user">No Hp :</label>
              <Controller control={control} name={`shipper_info.shipper_phone`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="user">Alamat :</label>
              <Controller control={control} name={`shipper_info.shipper_address`} render={({ field }) => <InputTextarea rows={5} value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Provinsi:</label>
              <Controller control={control} name={`shipper_info.shipper_province`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="user">Kota:</label>
              <Controller control={control} name={`shipper_info.shipper_city`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Kecamatan:</label>
              <Controller control={control} name={`shipper_info.shipper_district`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            {previewData?.shipper_info?.shipper_urban ? (
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Kelurahan:</label>
                <Controller control={control} name={`shipper_info.shipper_urban`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
            ) : null}
            {previewData?.shipper_info?.shipper_zip ? (
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Kode Pos:</label>
                <Controller control={control} name={`shipper_info.shipper_zip`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
            ) : null}

            <div className="field col-12 md:col-12 mt-4">
              <p className="font-bold uppercase">Alamat Penerima</p>
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="user">Nama :</label>
              <Controller control={control} name={`receiver_info.receiver_name`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="user">No Hp :</label>
              <Controller control={control} name={`receiver_info.receiver_phone`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="user">Alamat :</label>
              <Controller control={control} name={`receiver_info.receiver_address`} render={({ field }) => <InputTextarea rows={5} value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Provinsi:</label>
              <Controller control={control} name={`receiver_info.receiver_province`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="user">Kota:</label>
              <Controller control={control} name={`receiver_info.receiver_city`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="user">Kecamatan:</label>
              <Controller control={control} name={`receiver_info.receiver_district`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
            </div>
            {previewData?.receiver_info?.receiver_urban ? (
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Kelurahan:</label>
                <Controller control={control} name={`receiver_info.receiver_urban`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
            ) : null}
            {previewData?.receiver_info?.receiver_zip ? (
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Kode Pos:</label>
                <Controller control={control} name={`receiver_info.receiver_zip`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
              </div>
            ) : null}
          </div>
        </TabPanel>
      </TabView>
    </>
  );
};
