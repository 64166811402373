import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import Api from "../api/Api";
import useUserStore from "../stores/userStore";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import BalanceCard from "../components/BalanceCard";
import { Calendar } from "primereact/calendar";
import dateFormatYYYYMMDD from "../utils/dateFormatYYYYMMDD";

const Dashboard = () => {
  const navigate = useNavigate();
  const bank_modal_alert_localstorage = localStorage.getItem("bank_modal_alert");

  // state
  let date = new Date();
  let first_month_date = new Date(date.getFullYear(), date.getMonth(), 1);
  const [rangeDate, setRangeDate] = useState([first_month_date, date]);
  const [minDate, setMinDate] = useState(first_month_date);
  const [maxDate, setMaxDate] = useState(date);
  const [showModalAlertBank, setModalAlertBank] = useState(false);

  // store
  const userStore = useUserStore((state) => state);

  // react query
  const {
    data: dashboardData,
    isLoading,
    refetch,
  } = useQuery(
    ["dashboard", userStore.user._id, minDate, maxDate],
    async () => {
      let min_date = dateFormatYYYYMMDD(minDate);
      let max_date = maxDate ? dateFormatYYYYMMDD(maxDate) : min_date;

      try {
        const res = await Api().get(`/dashboard/count?start_date=${min_date}&end_date=${max_date}`);
        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }
        return res.data.data;
      } catch (error) {
        toast.error(error.message);
      }
    },
    { initialData: {} }
  );
  const { data: bankInformation } = useQuery(
    ["bank-info", userStore.user._id],
    async () => {
      try {
        const res = await Api().get("/user/check-user-bank");
        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        return res.data.data;
      } catch (error) {
        toast.error(error.message);
      }
    },
    { initialData: {} }
  );

  const getPeriode = () => {
    let min_date = dateFormatYYYYMMDD(minDate);
    let max_date = maxDate ? dateFormatYYYYMMDD(maxDate) : min_date;

    return `${min_date}/${max_date}`;
  };
  const handleCloseBankAlert = () => {
    setModalAlertBank(false);
    localStorage.setItem("bank_modal_alert", true);
  };

  // lifecycle
  useEffect(() => {
    if (bankInformation?.bank_name) {
      if (!bankInformation?.exists_bank_info && !bank_modal_alert_localstorage) {
        setModalAlertBank(!bankInformation?.exists_bank_info);
      }
    }
  }, [bankInformation, bank_modal_alert_localstorage]);

  useEffect(() => {
    refetch();
  }, [minDate, maxDate, refetch]);

  return (
    <>
      <div className="flex grid ">
        <div className="col-12 md:col-6 xl:col-4">
          <label htmlFor="choose-kurir" className="pb-2 block uppercase">
            periode waktu:
          </label>
          <Calendar
            id="range"
            className="w-full"
            selectionMode="range"
            placeholder="Pilih Periode Waktu"
            value={rangeDate}
            onChange={(e) => {
              setRangeDate(e.value);
              setMinDate(e.value[0]);
              setMaxDate(e.value[1]);
            }}
            maxDate={new Date()}
          />
        </div>
      </div>

      {/* uang cod */}
      <div className="w-full mt-3">
        <h4 style={{ fontSize: "14px" }} className="font-bold uppercase">
          Dana Terkumpul
        </h4>
      </div>
      <div className="grid">
        <BalanceCard isLoading={isLoading} icon="fa-money-bill-wave" className="col-12 md:col-6 xl:col-4" title="Dana COD Terkumpulkan" balance={dashboardData?.cod_collected} />
        <BalanceCard isLoading={isLoading} icon="fa-money-bill-wave" className="col-12 md:col-6 xl:col-4" title="Dana NON-COD Terkumpulkan" balance={dashboardData?.noncod_collected} />
      </div>
      {/* end uang cod */}

      <div className="col-12 grid p-0 m-0 mt-3">
        {/* cod */}
        <div className="w-full">
          <h4 style={{ fontSize: "14px" }} className="font-bold">
            COD
          </h4>
        </div>

        <div className="grid">
          <div onClick={() => navigate(`/dashboard/details?delivery_method=1&delivery_satatus=1&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card ">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-box" className="w-full" title="cod ready to pickup" balance={dashboardData?.cod_on_progress} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=1&delivery_satatus=2&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card ">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-truck" className="w-full" title="COD Sedang Dikirim" balance={dashboardData?.cod_delivery} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=1&delivery_satatus=3&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card ">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-people-carry-box" className="w-full" title="COD Berhasil Terkirim" balance={dashboardData?.cod_delivered} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=1&delivery_satatus=4&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card ">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-rotate-left" className="w-full" title="cod sedang dikembalikan" balance={dashboardData?.cod_return} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=1&delivery_satatus=5&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card ">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-rotate-left" className="w-full" title="cod berhasil dikembalikan" balance={dashboardData?.cod_returned} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=1&delivery_satatus=7&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card ">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-triangle-exclamation" className="w-full" title="COD Bermasalah" balance={dashboardData?.cod_problem} />
          </div>
        </div>
        {/* end cod */}

        {/* non cod */}
        <div className="w-full">
          <h4 style={{ fontSize: "14px" }} className="font-bold">
            NON-COD
          </h4>
        </div>
        <div className="grid">
          <div onClick={() => navigate(`/dashboard/details?delivery_method=2&delivery_satatus=1&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card ">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-box" className="w-full" title="NON cod ready to pickup" balance={dashboardData?.noncod_on_progress} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=2&delivery_satatus=2&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-truck" className="w-full" title="NON COD Sedang Dikirim" balance={dashboardData?.noncod_delivery} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=2&delivery_satatus=3&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-people-carry-box" className="w-full" title="NON COD Berhasil Terkirim" balance={dashboardData?.noncod_delivered} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=2&delivery_satatus=7&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-triangle-exclamation" className="w-full" title="non COD Bermasalah" balance={dashboardData?.noncod_problem} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=2&delivery_satatus=4&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-rotate-left" className="w-full" title="non cod sedang dikembalikan" balance={dashboardData?.noncod_return} />
          </div>
          <div onClick={() => navigate(`/dashboard/details?delivery_method=2&delivery_satatus=5&periode=${getPeriode()}`)} className="col-12 md:col-6 xl:col-4 cursor-pointer dashboard-card">
            <BalanceCard parseToMoney={false} isLoading={isLoading} icon="fa-rotate-left" className="w-full" title="non cod berhasil dikembalikan" balance={dashboardData?.noncod_returned} />
          </div>
        </div>
        {/* end non cod */}
      </div>

      {/* modal info user need to fill bank info */}
      <Dialog
        visible={showModalAlertBank}
        header="Perhatian"
        modal
        className="p-fluid modal-container"
        onHide={handleCloseBankAlert}
        footer={() => (
          <div className="mt-4">
            <Button onClick={handleCloseBankAlert} label="Tutup" className="p-button-secondary" />
          </div>
        )}
      >
        <p>
          Anda belum mengisi informasi Bank , Silahkan untuk isi informasi{" "}
          <Link to="/dashboard/my-bank">
            <span className="underline font-bold">DISINI</span>
          </Link>
        </p>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
