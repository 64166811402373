// hooks
import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";
// import { DevTool } from "@hookform/devtools";

// conponents
import "react-quill/dist/quill.snow.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// config
import { InputNumber } from "primereact/inputnumber";

// api related
import PagesApi from "../../api/PagesApi";
import routeGroup from "../../pages-group.json";

const defaultValues = {
  icon: "",
};

const CreateProduct = () => {
  const navigate = useNavigate();

  // hooks
  const { control, register, handleSubmit } = useForm();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "data",
  });

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await PagesApi.Add(data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/pages");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    crateMutate(data.data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Pages
          </h4>
        </div>
      </React.Fragment>
    );
  };

  // lifecycle
  useEffect(() => {
    append(defaultValues);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card col-12 mx-auto">
        <Toolbar className="mb-4" left={leftToolbar} />
        {fields.map((item, index) => (
          <div key={item.id}>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-2">
                <label htmlFor="user">Route Name :</label>
                <InputText placeholder="Input page name" {...register(`data.${index}.name`)} id="name" type="text" />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Route Path :</label>
                <InputText placeholder="Example /dashboard/..." {...register(`data.${index}.route`)} id="route" type="text" />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Icon :</label>
                <InputText placeholder="Input product name" {...register(`data.${index}.icon`)} id="name" type="text" />
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="group">Group : </label>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`data.${index}.group`}
                  render={({ field }) => <Dropdown onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} options={routeGroup} optionLabel="name" placeholder="Route Group" />}
                />
              </div>
              <div className="field col-12 md:col-1">
                <label htmlFor="order">Order :</label>
                <Controller defaultValue="" control={control} name={`data.${index}.order`} render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
              </div>
              <div className="field col-12 md:col-1">
                <label className="block"></label> <br />
                <Button icon="pi pi-times block" onClick={() => remove(index)} className=" p-button-danger mr-4" />
              </div>
            </div>
          </div>
        ))}
        <section>
          <Button type="button" label="Add Field" onClick={() => append(defaultValues)} className=" p-button-primary mr-4" />
        </section>
        <div className="flex justify-content-center mt-4">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/pages">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(CreateProduct, comparisonFn);
