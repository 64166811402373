import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { Controller } from "react-hook-form";

const AddressFieldOrderDetails = ({ control, preFixKey, preFix, data }) => {
  return (
    <>
      <div className="field col-12 md:col-4">
        <label htmlFor="user">Nama :</label>
        <Controller control={control} name={`${preFixKey}.${preFix}_name`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="user">E-Mail :</label>
        <Controller control={control} name={`${preFixKey}.${preFix}_email`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="user">No Hp :</label>
        <Controller control={control} name={`${preFixKey}.${preFix}_phone`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="user">Alamat :</label>
        <Controller control={control} name={`${preFixKey}.${preFix}_address`} render={({ field }) => <InputTextarea rows={5} value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="user">Provinsi:</label>
        <Controller control={control} name={`${preFixKey}.${preFix}_province`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
      </div>

      <div className="field col-12 md:col-3">
        <label htmlFor="user">Kota:</label>
        <Controller control={control} name={`${preFixKey}.${preFix}_city`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="user">Kecamatan:</label>
        <Controller control={control} name={`${preFixKey}.${preFix}_district`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
      </div>
      {preFixKey === "receiver_info" && data.receiver_info?.receiver_urban && (
        <div className="field col-12 md:col-3">
          <label htmlFor="user">Kelurahan:</label>
          <Controller control={control} name={`${preFixKey}.${preFix}_urban`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
        </div>
      )}
      {preFixKey === "shipper_info" && data.shipper_info?.shipper_urban && (
        <div className="field col-12 md:col-3">
          <label htmlFor="user">Kelurahan:</label>
          <Controller control={control} name={`${preFixKey}.${preFix}_urban`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
        </div>
      )}

      {preFixKey === "receiver_info" && data.receiver_info?.receiver_zip && (
        <div className="field col-12 md:col-3">
          <label htmlFor="user">Kode Pos:</label>
          <Controller control={control} name={`${preFixKey}.${preFix}_zip`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
        </div>
      )}
      {preFixKey === "shipper_info" && data.shipper_info?.shipper_zip && (
        <div className="field col-12 md:col-3">
          <label htmlFor="user">Kode Pos:</label>
          <Controller control={control} name={`${preFixKey}.${preFix}_zip`} render={({ field }) => <InputText value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" disabled />} />
        </div>
      )}
    </>
  );
};

export default AddressFieldOrderDetails;
