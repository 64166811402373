// hooks
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { Controller, useForm, useFieldArray } from "react-hook-form";

// components
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { Timeline } from "primereact/timeline";
import { Image } from "primereact/image";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";

// utils
import Api from "../../api/Api";
import classNames from "classnames";

const CheckResi = () => {
  const { isLoading: isLoadingGetResi, mutate } = useMutation(async (data) => await Api().post("/courier/multiple-resi", data), {
    onSettled: (res, error) => {
      if (error) {
        return toast.error("Gagal melacak resi");
      }

      if (res.data.status !== 200) {
        return toast.error(res.data.message);
      }

      if (!res.data.data.length) {
        return toast.error("Gagal melacak resi");
      }

      setResults(res.data.data);
      setActiveIndex(1);
      reset({});
      for (let i = 0; i < 3; i++) {
        append({});
      }
    },
  });

  const { control, handleSubmit, reset, watch, formState } = useForm();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "resis",
  });

  const resis = watch("resis", []);
  const [expandedRows, setExpandedRows] = useState(null);
  const [results, setResults] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const submitHandler = async (data) => {
    let payload = [];
    for (let i = 0; i < data.resis.length; i++) {
      if (data.resis[i].no_resi) {
        payload.push(data.resis[i].no_resi);
      }
    }
    if (!payload.length) {
      return toast.error("Masukkan minimal 1 resi");
    }
    mutate({ resis: payload });
  };

  // child component
  const rowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable py-4">
        {data.track_history.length ? (
          <Timeline
            style={{ maxHeight: "200px", overflowY: "auto" }}
            value={data.track_history.reverse()}
            align="alternate"
            layout="vertical"
            content={(item) => (
              <div className="text-sm">
                <h6>{item.date}</h6>
                <p>{item.description}</p>
              </div>
            )}
          />
        ) : (
          <div>
            RESI : <b>{data.waybill}</b>, Sedang Diperoses.
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    for (let i = 0; i < 3; i++) {
      append({});
    }
  }, [append]);

  return (
    <div className="p-fluid formgrid grid card col-12 xl:col-11 mx-auto">
      <Toolbar
        className="mb-4 py-4 col-12"
        left={() => (
          <div className="px-2">
            <h1 className="text-xl uppercase p-0 m-0">Form Cek Resi</h1>
          </div>
        )}
      />

      <div className="col-12">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Form Cek Resi">
            <form onSubmit={handleSubmit(submitHandler)} className="p-fluid grid formgrid mt-4" style={{ gap: "5px" }}>
              {fields.map((item, index) => (
                <div key={item.id} className="w-full mx-auto grid">
                  <div className="field col-12 relative flex flex-column">
                    <label htmlFor="choose-origin">No Resi :</label>
                    <Controller
                      rules={{ required: false }}
                      control={control}
                      defaultValue={""}
                      name={`resis.${index}.no_resi`}
                      render={({ field }) => <InputText ref={field.ref} className={classNames({ "p-invalid": formState.errors?.resis?.length && formState.errors?.resis[index]?.no_resi })} id="resi" value={field.value} onChange={(e) => field.onChange(e)} placeholder="Masukkan no resi" />}
                    />
                    <div style={{ right: "10px" }} className="absolute bottom-0">
                      <Button icon="pi pi-trash" onClick={() => remove(index)} style={{ visibility: index < 1 && "hidden" }} className="p-button-danger p-button-text" />
                    </div>
                  </div>
                </div>
              ))}
              {resis?.length < 10 ? (
                <div className="w-full">
                  <div className="col-12 pl-2">
                    <div style={{ maxWidth: "150px" }}>
                      <Button className="p-button-text p-0" label="Tambah Kolom" icon="pi pi-plus" onClick={() => append({})} type="button" />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="flex justify-content-center mt-5 w-full gap-1">
                <div className="mr-4">
                  <Button loading={isLoadingGetResi} label="Lacak Resi" className="p-button-success " />
                </div>
                <div className="">
                  <Link to="/dashboard/products">
                    <Button type="button" label="Kembali" className="p-button-secondary" />
                  </Link>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel header="Hasil Cek Resi" disabled={!Object.keys(results).length}>
            <div className="field w-full">
              <DataTable value={results} responsiveLayout="scroll" dataKey="waybill" className="mt-3" expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate}>
                <Column expander={(data) => data.track_history.length} style={{ width: "3em" }} />
                <Column field="waybill" header="Resi" />
                <Column field="weight" header="Berat" body={(fields) => `${fields.weight} Kg`} />
                <Column field="origin" header="Tujuan Awal" />
                <Column field="destination" header="Tujuan Akhir" />
                <Column field="receiver_name" header="Penerima" />
                <Column field="status" header="Status" body={(fields) => (fields.status ? fields.status : "-")} />
                <Column field="pod_date" header="Diterima Pada" />
                <Column field="pod_photo" header="Bukti" body={(fields) => (fields?.pod_photo ? <Image width={80} height={80} src={fields.pod_photo} alt="pod_photo" preview /> : "-")} />
              </DataTable>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default CheckResi;
