// hooks
import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import "react-quill/dist/quill.snow.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

// config
import { InputNumber } from "primereact/inputnumber";

// api related
import classNames from "classnames";
import Api from "../../api/Api";
import { useGetRole } from "../../hook/role.hook";
const defaultValues = {};

const UpdateUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // hooks
  const { control, handleSubmit, formState, reset, getValues } = useForm();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "courier_percentages",
  });

  // query
  const { data: roleOptions } = useGetRole({ filter_super_admin: false });
  const { data: courierData, isLoading: loadingCourier } = useQuery("couriers", async () => await getCouriers(), { defaultValue: [] });
  const { isLoading: loadingUser } = useQuery(["user", id], async () => await getUserDetails(), { defaultValue: [] });
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/user/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/users");
        toast.success("User Updated", { duration: 5000 });
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    crateMutate({ ...data, user_id: data._id });
  };
  const getCouriers = async () => {
    const response = await Api().get("courier");
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data.data;
  };
  const getUserDetails = async () => {
    const response = await Api().get(`/user/detail/${id}`);
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    reset(response.data.data);
    if (!response.data.data.courier_percentages.length) {
      append({});
    }
    return response.data.data;
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="col-12">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update User
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0" }} className="card">
            <Toolbar className="mb-4" left={leftToolbar} />
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="name">Nama: </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="name"
                  name="name"
                  render={({ field }) => <InputText placeholder="Masukkan nama" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors.name && "p-invalid"} />}
                />
                {formState.errors.name && (
                  <small id="name" className="p-error block pt-1">
                    Masukkan nama
                  </small>
                )}
              </div>
              <div className="field col-12">
                <label htmlFor="alias_name">Nama Reseller: </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="alias_name"
                  name="alias_name"
                  render={({ field }) => <InputText placeholder="Masukkan nama reseller" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="alias_name" type="text" className={formState.errors.alias_name && "p-invalid"} />}
                />
                {formState.errors.alias_name && (
                  <small id="alias_name" className="p-error block pt-1">
                    Masukkan nama reseller
                  </small>
                )}
              </div>
              <div className="field col-12">
                <label htmlFor="email">E-Mail: </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="email"
                  name="email"
                  render={({ field }) => <InputText placeholder="Masukkan email" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="email" type="text" className={formState.errors.name && "p-invalid"} />}
                />
                {formState.errors.name && (
                  <small id="name" className="p-error block pt-1">
                    Masukkan nama
                  </small>
                )}
              </div>
              <div className="field col-12">
                <label htmlFor="phone_number">No Hp : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="phone_number"
                  name="phone_number"
                  render={({ field }) => (
                    <InputText
                      placeholder="Masukkan no telp"
                      value={field.value}
                      onBlur={field.onBlur}
                      ref={field.ref}
                      onChange={(e) => field.onChange(e)}
                      id="phone_number"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className={formState.errors.phone_number && "p-invalid"}
                    />
                  )}
                />
                {formState.errors.phone_number && (
                  <small id="phone_number" className="p-error block pt-1">
                    Masukkan no telp
                  </small>
                )}
              </div>
              <div className="field col-12 ">
                <label htmlFor="choose-kurir">Role :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="role_id"
                  render={({ field }) => (
                    <Dropdown
                      ref={field.ref}
                      optionLabel="name"
                      optionValue="_id"
                      disabled={!roleOptions?.length}
                      value={field.value}
                      onBlur={field.onBlur}
                      options={roleOptions}
                      className={classNames({
                        "p-invalid": formState.errors.role_id,
                      })}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      placeholder="Pilih Role"
                    />
                  )}
                />
                {formState.errors.role_id && (
                  <small id="choose-kurir" className="p-error block pt-1">
                    Anda belum role
                  </small>
                )}
              </div>
              <div className="field col-12 mt-4 ">
                <h4 style={{ fontSize: "14px", fontWeight: "bold" }}>Informasi Bank</h4>
              </div>
              <div className="field col-12">
                <label htmlFor="bank_account_number">Nomor Rekening : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="bank_account_number"
                  render={({ field }) => (
                    <InputText
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Masukkan nomor rekening"
                      value={field.value}
                      onBlur={field.onBlur}
                      ref={field.ref}
                      onChange={(e) => field.onChange(e)}
                      id="bank_account_number"
                      type="text"
                      className={formState.errors.bank_account_number && "p-invalid"}
                    />
                  )}
                />
                {formState.errors.bank_account_number && (
                  <small id="bank_account_number" className="p-error block pt-1">
                    Masukkan nomor rekening
                  </small>
                )}
              </div>
              <div className="field col-12">
                <label htmlFor="bank_user_name">Nama Penerima :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="bank_user_name"
                  render={({ field }) => <InputText placeholder="Masukkan nama penerima" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="bank_user_name" type="text" className={formState.errors.bank_user_name && "p-invalid"} />}
                />
                {formState.errors.bank_user_name && (
                  <small id="bank_user_name" className="p-error block pt-1">
                    Masukkan nama penerima
                  </small>
                )}
              </div>
              <div className="field col-12">
                <label htmlFor="bank_name">Nama Bank : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="bank_name"
                  render={({ field }) => <InputText placeholder="Contoh BCA , Mandiri ..." value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="bank_name" type="text" className={formState.errors.bank_name && "p-invalid"} />}
                />
                {formState.errors.bank_name && (
                  <small id="bank_name" className="p-error block pt-1">
                    Masukkan nomor rekening
                  </small>
                )}
              </div>
              <div className="field col-12 mt-4 ">
                <h4 style={{ fontSize: "14px", fontWeight: "bold" }}>User Discount Setting</h4>
              </div>
              <div className="w-full flex flex-column " style={{ rowGap: "25px" }}>
                {fields.map((item, index) => (
                  <div key={item.id}>
                    <div className="p-fluid formgrid grid col-12">
                      <div className="field col-12 md:col-3 lg:col-4">
                        <label htmlFor="name">Pilih Kurir : </label>
                        <Controller
                          rules={{ required: true }}
                          control={control}
                          defaultValue={""}
                          name={`courier_percentages.${index}.courier_id`}
                          render={({ field }) => (
                            <Dropdown
                              ref={field.ref}
                              optionLabel="name"
                              optionValue="_id"
                              disabled={!courierData?.length}
                              value={field.value}
                              onBlur={field.onBlur}
                              options={courierData}
                              className={classNames({
                                "p-invalid": formState.errors.courier_percentages?.index?.courier_id,
                              })}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              placeholder={loadingCourier ? "loading..." : !loadingCourier && !courierData.length ? "Kurir Tidak Ada" : "Pilih Kurir"}
                            />
                          )}
                        />
                        {formState.errors.courier_percentages?.index?.courier_id && (
                          <small id="choose-kurir" className="p-error block pt-1">
                            Anda belum memilih kurir
                          </small>
                        )}
                      </div>
                      <div className="field col-12 md:col-3 lg:col-2">
                        <label htmlFor="cod_percentage">Persentase COD (%): </label>
                        <Controller
                          control={control}
                          name={`courier_percentages.${index}.cod_percentage`}
                          defaultValue={0}
                          render={({ field }) => (
                            <InputNumber
                              minFractionDigits={2}
                              onBlur={field.onBlur}
                              ref={field.ref}
                              value={field.value}
                              onValueChange={(e) => {
                                field.onChange(e);
                              }}
                              mode="decimal"
                              placeholder="Masukkan persentase cod"
                              className={classNames({
                                "p-invalid": formState.errors?.courier_percentages?.index?.cod_percentage,
                              })}
                            />
                          )}
                        />
                        {formState.errors?.courier_percentages?.index?.cod_percentage && (
                          <small id="cod_percentage" className="p-error block pt-1">
                            Masukkan persentase cod
                          </small>
                        )}
                      </div>
                      <div className="field col-12 md:col-3 lg:col-2">
                        <label htmlFor="discount_percentage">Persentase Diskon (%): </label>
                        <Controller
                          control={control}
                          name={`courier_percentages.${index}.discount_percentage`}
                          defaultValue={0}
                          render={({ field }) => (
                            <InputNumber
                              minFractionDigits={2}
                              onBlur={field.onBlur}
                              ref={field.ref}
                              value={field.value}
                              onValueChange={(e) => {
                                field.onChange(e);
                              }}
                              mode="decimal"
                              placeholder="Masukkan persentase diskon"
                              className={classNames({
                                "p-invalid": formState.errors?.courier_percentages?.index?.discount_percentage,
                              })}
                            />
                          )}
                        />
                        {formState.errors?.courier_percentages?.index?.discount_percentage && (
                          <small id="discount_percentage" className="p-error block pt-1">
                            Masukkan persentase diskon
                          </small>
                        )}
                      </div>
                      <div className="field col-12 md:col-3 lg:col-2">
                        <label htmlFor="insurance_percentage">Persentase Asuransi (%): </label>
                        <Controller
                          control={control}
                          name={`courier_percentages.${index}.insurance_percentage`}
                          defaultValue={0}
                          render={({ field }) => (
                            <InputNumber
                              minFractionDigits={2}
                              onBlur={field.onBlur}
                              ref={field.ref}
                              value={field.value}
                              onValueChange={(e) => {
                                field.onChange(e);
                              }}
                              mode="decimal"
                              placeholder="Masukkan persentase cod"
                              className={classNames({
                                "p-invalid": formState.errors.courier_percentages?.index?.insurance_percentage,
                              })}
                            />
                          )}
                        />
                        {formState.errors.courier_percentages?.index?.insurance_percentage && (
                          <small id="insurance_percentage" className="p-error block pt-1">
                            Masukkan persentase Asuransi
                          </small>
                        )}
                      </div>
                      <div className="field col-12 md:col-3 lg:col-2">
                        <label>Action</label> <br />
                        <Button label="Hapus Field" icon="pi pi-times block" onClick={() => remove(index)} className=" p-button-danger mr-4" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <section className="w-full mt-4">
                <div className="col-12 md:col-3 lg:col-2">
                  <Button disabled={courierData?.length === getValues()?.courier_percentages?.length} type="button" label="Add Field" onClick={() => append(defaultValues)} className=" p-button-primary mr-4" />
                </div>
              </section>
              <div className="w-full flex justify-content-center mt-4">
                <div className="col-6 md:col-3 xl:col-2">
                  <Button disabled={loadingUser} label="Save" loading={createLoading} className=" p-button-primary mr-4" />
                </div>
                <div className="col-6 md:col-3 xl:col-2">
                  <Link to="/dashboard/users">
                    <Button type="button" label="Back" className=" p-button-secondary" />
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateUser, comparisonFn);
