// hooks
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

// components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Link, useNavigate } from "react-router-dom";

// utils
import classnames from "classnames";
import toast from "react-hot-toast";

import Logo from "../assets/images/logo_with_text.png";
import Api from "../api/Api";
import { Dialog } from "primereact/dialog";

export default function Register() {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState(null);
  const password = useRef({});
  password.current = watch("password", "");

  const { isLoading, mutate: CreateUser } = useMutation(async (data) => Api().post("/user/sign-up", data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        let error = response.data.message;
        toast.error(error, { duration: 4000 });
        return;
      }
      setShowModal(true);
    },
  });

  const onSubmit = async (data) => {
    setEmail(data.email);
    CreateUser({ ...data, organization_id: process.env.REACT_APP_ORGANIZATION_ID, user_level: "user" });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex justify-content-center align-content-center w-full min-h-screen">
        <div className="flex align-items-center justify-content-center w-full">
          <div className="surface-card  shadow-2 col-11 lg:w-4 p-2">
            <div className="text-center mt-4 mb-2">
              <img width="140" src={Logo} alt="logo" />
            </div>
            <div style={{ margin: 0 }} className="p-fluid grid formgrid col-12 mt-4">
              <div className="field col-12 md:col-6">
                <label htmlFor="first_name" className="block text-900 font-medium">
                  Nama Depan
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="first_name"
                  render={({ field }) => <InputText id="first_name" placeholder="Masukkan nama" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={classnames("w-full", { "p-invalid": errors.first_name })} />}
                />
                {errors.first_name && (
                  <small id="name" className="p-error block pt-1">
                    Masukkan nama anda
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="last_name" className="block text-900 font-medium">
                  Nama Belakang
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="last_name"
                  render={({ field }) => <InputText id="last_name" placeholder="Masukkan nama" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={classnames("w-full", { "p-invalid": errors.last_name })} />}
                />
                {errors.last_name && (
                  <small id="name" className="p-error block pt-1">
                    Masukkan nama anda
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="phone_number" className="block text-900 font-medium">
                  No Handphone
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="phone_number"
                  render={({ field }) => (
                    <InputText
                      id="phone_number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Masukkan no hp"
                      onBlur={field.onBlur}
                      onChange={(e) => field.onChange(e)}
                      type="text"
                      value={field.value}
                      className={classnames("w-full", { "p-invalid": errors.phone_number })}
                    />
                  )}
                />
                {errors.phone_number && (
                  <small id="phone_number" className="p-error block pt-1">
                    No Handphone dibutuhkan
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="email" className="block text-900 font-medium ">
                  Email
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="email"
                  render={({ field }) => <InputText id="email" autoComplete="username" placeholder="input email" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={classnames("w-full", { "p-invalid": errors.email })} />}
                />
                {errors.email && (
                  <small id="name" className="p-error block pt-1">
                    Masukkan E-mail anda
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="email" className="block text-900 font-medium mb-2">
                  Password
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="password"
                  render={({ field }) => <Password value={field.value} autoComplete="current-password" placeholder="Masukkan password" onChange={(e) => field.onChange(e)} toggleMask feedback={false} className="w-full" inputClassName={classnames("w-full", { "p-invalid": errors.password })} />}
                />
                {errors.password && (
                  <small id="name" className="p-error block pt-1">
                    Masukkan password
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="confirm_password" className="block text-900 font-medium mb-2">
                  Konfirmasi Password
                </label>
                <Controller
                  rules={{ validate: (value) => value === password.current || "Pssword tidak sama", required: true }}
                  control={control}
                  name="confirm_password"
                  render={({ field }) => (
                    <Password value={field.value} autoComplete="current-password" placeholder="Masukkan konfirmasi password" onChange={(e) => field.onChange(e)} toggleMask feedback={false} className="w-full" inputClassName={classnames("w-full", { "p-invalid": errors.confirm_password })} />
                  )}
                />
                {errors.confirm_password && (
                  <small id="confirm_password" className="p-error block pt-1">
                    {errors.confirm_password.message}
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <Button loading={isLoading} label="Daftar" className="w-full mt-2 p-button-success" />
                <div className="w-full mt-3">
                  <div className="">
                    <p className="text-center">
                      Sudah Punya Akun ?{" "}
                      <Link to="/login">
                        <span style={{ color: "black" }} className="font-medium underline">
                          Login
                        </span>
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Dialog visible={showModal} className="modal-container" header="Notifikasi" modal footer={<Button onClick={() => navigate("/login")} label="Masuk Sekarang" className="p-button-success p-button-text" />} onHide={() => setShowModal(false)}>
        <div className="flex mb-5">
          <p>
            {/* <span className="font-bold">{email}</span> Berhasil didaftarkan silahkan cek email untuk melakukan verifikasi */}
            <span className="font-bold">{email}</span> Akun berhasil dibuat.
          </p>
        </div>
      </Dialog>
    </>
  );
}
