import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo-mobile.png";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import useUserStore from "../stores/userStore";

export const AppTopbar = (props) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const userStore = useUserStore((state) => state);

  const logout = () => {
    userStore.resetUser();
    Cookies.remove("token");
    localStorage.removeItem("bank_modal_alert");
    navigate("/login");
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="Tidak" icon="pi pi-times" className="p-button-text" onClick={() => setShowModal(false)} />
        <Button label="Ya" icon="pi pi-check" className="p-button-text" onClick={logout} />
      </>
    );
  };

  return (
    <>
      <div className="layout-topbar">
        <div className="layout-topbar-logo w-full md:w-auto flex justify-content-between" style={{ gap: "40px" }}>
          <Link to="/">
            <div className="align-items-center h-2rem md:h-3rem pl-4 md:pl-0">
              <img className="w-full h-full" src={logo} alt="logo" />
            </div>
          </Link>
          <Button type="button" onClick={() => setShowModal(true)} icon="pi pi-sign-out" label="" className="p-button-danger lg:hidden" />
        </div>
        <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
          <i className="pi pi-bars" />
        </button>

        <ul className="layout-topbar-menu lg:flex origin-top" style={{ gap: "1rem" }}>
          {/* <li>
            <Button onClick={() => navigate("/dashboard/account")} icon="pi pi-wallet" label="Rp. 5.000.000" className="p-button-secondary p-button-text" />
          </li> */}
          <li>
            <Button onClick={() => navigate("/dashboard/account")} icon="pi pi-user" label={userStore.user.name} className="p-button-success" />
          </li>
          <li>
            <Button onClick={() => setShowModal(true)} icon="pi pi-sign-out" label="Keluar" className="p-button-danger" />
          </li>
        </ul>
      </div>
      <Dialog visible={showModal} className="modal-container" header="Konfirmasi Logout" modal footer={deleteSingleItemFooter} onHide={() => setShowModal(false)}>
        <div className="flex align-items-center py-3">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          <span>Anda yakin akan keluar dari akun ?</span>
        </div>
      </Dialog>
    </>
  );
};
