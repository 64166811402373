import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import PriceEstimationForm from "../PriceEstimationForm";
import toast from "react-hot-toast";
import Api from "../../../api/Api";
import { useMutation, useQuery } from "react-query";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

const ProductInformation = ({ setDisableButton, step, watch, control, formState, origin, destination, reset, getValues, setEstimatePrice, setLoadingCheckEstimate }) => {
  const [estimate, setEstimate] = useState({});
  const item_qty = watch("item_qty");
  const item_weight = watch("item_weight");
  const item_price_non_cod = watch("item_price");
  const item_price_cod = watch("cod_price");
  const is_insurance_value = watch("insurance");

  const { data: catData } = useQuery(
    "product-cat",
    async () => {
      try {
        const res = await Api().get("category");
        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }
        return res.data.data;
      } catch (error) {
        toast.error(error.message);
      }
    },
    { initialData: [] }
  );

  const { mutate: getEstimate, isLoading } = useMutation(async (data) => await Api().post("/courier/estimate-price", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }
      if (response.data.status !== 200) {
        reset({ ...getValues(), item_price: 0, cod_price: 0 });
        setEstimate({});
        setDisableButton(true);
        return toast.error(response.data.message);
      }

      setDisableButton(false);
      setEstimatePrice(response.data.data);
      setEstimate(response.data.data);
    },
  });
  const { mutate: getEstimateInsurance, isLoading: loadingInsurance } = useMutation(async (data) => await Api().post("/courier/estimate-insurance", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }

      if (response.data.status !== 200) {
        reset({ ...getValues(), item_price: 0, cod_price: 0 });
        setEstimate({});
        setDisableButton(true);
        return toast.error(response.data.message);
      }

      reset({ ...getValues(), insurance_price: response.data.data.insurance_price });
      // setDisableButton(false);
      // setEstimatePrice(response.data.data);
      // setEstimate(response.data.data);
    },
  });

  useEffect(() => {
    setLoadingCheckEstimate(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step === 2) {
      if (item_price_cod > 0 || item_price_non_cod > 0) {
        let value = getValues();
        if (item_weight > 0) {
          getEstimate({
            courier_id: value.courier_id,
            origin: origin,
            destination: destination,
            service_type_code: value.service_type,
            weight: item_weight,
            cod_value: item_price_cod,
            item_price: item_price_non_cod,
          });
        }
      } else {
        setDisableButton(true);
        setEstimatePrice({});
        setEstimate({ fee_cod: 0, tax_percentage: 0, fee_tax: 0, discount_percentage: 0, fee_discount: 0, shipping_cost: 0, total_price: 0 });
      }
    }
  }, [item_qty, item_weight, item_price_non_cod, item_price_cod, step, destination, origin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (is_insurance_value) {
      if (item_price_non_cod > 0 || item_price_cod > 0) {
        let current_price = 0;

        if (item_price_non_cod > 0) current_price = item_price_non_cod;
        if (item_price_cod > 0) current_price = item_price_cod;

        const current_values = getValues();
        getEstimateInsurance({
          courier_id: current_values.courier_id,
          service_type_code: current_values.service_type,
          item_price: current_price,
          cod: item_price_cod > 0,
          shipping_cost: estimate.shipping_cost,
        });
      }
    } else {
      reset({ ...getValues(), insurance_price: 0 });
    }
  }, [item_price_non_cod, item_price_cod, is_insurance_value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-fluid grid formgrid w-full m-0">
      <div className="field col-12 md:col-6">
        <label htmlFor="item_name">Nama Produk : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="item_name"
          render={({ field }) => <InputText placeholder="Masukkan nama produk" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="item_name" type="text" className={formState.errors.item_name && "p-invalid"} />}
        />
        {formState.errors.item_name && (
          <small id="receiver_info.receiver_phone" className="p-error block pt-1">
            Masukkan nama produk
          </small>
        )}
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="choose-kurir">Kategori Produk :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="item_category"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              optionLabel="name"
              optionValue="name"
              disabled={!catData.length}
              value={field.value}
              onBlur={field.onBlur}
              options={catData}
              className={classNames({
                "p-invalid": formState.errors.item_category,
              })}
              onChange={(e) => {
                field.onChange(e);
              }}
              placeholder="Pilih jenis produk"
            />
          )}
        />
        {formState.errors.item_category && (
          <small id="choose-kurir" className="p-error block pt-1">
            Anda belum memilih kategori produk
          </small>
        )}
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="item_weight">Berat Barang (kg) : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={1}
          name="item_weight"
          render={({ field }) => (
            <InputNumber
              onBlur={field.onBlur}
              ref={field.ref}
              value={field.value}
              onKeyDown={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              min={1}
              onValueChange={(e) => field.onChange(e)}
              mode="decimal"
              placeholder="Masukkan berat barang"
              className={classNames({
                "p-invalid": formState.errors.item_weight,
              })}
            />
          )}
        />
        {formState.errors.item_weight && (
          <small id="item_weight" className="p-error block pt-1">
            Masukkan berat barang
          </small>
        )}
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="item_qty">Jumlah Barang : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={1}
          name="item_qty"
          render={({ field }) => (
            <InputNumber
              onBlur={field.onBlur}
              ref={field.ref}
              value={field.value}
              onKeyDown={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              min={1}
              onValueChange={(e) => field.onChange(e)}
              mode="decimal"
              placeholder="Masukkan jumlah barang"
              className={classNames({
                "p-invalid": formState.errors.item_qty,
              })}
            />
          )}
        />
        {formState.errors.item_qty && (
          <small id="item_qty" className="p-error block pt-1">
            Masukkan jumlah barang
          </small>
        )}
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="item_price">Nilai Barang NON COD :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="item_price"
          defaultValue={0}
          render={({ field }) => (
            <InputNumber
              onBlur={field.onBlur}
              ref={field.ref}
              value={field.value}
              onValueChange={(e) => {
                field.onChange(e);
                let current_values = getValues();
                if (e.value > 0) {
                  reset({ ...current_values, cod_price: 0, cod: false });
                } else {
                  reset({ ...current_values, item_price: 0, cod: true });
                }
              }}
              mode="decimal"
              placeholder="Masukkan harga barang"
              className={classNames({
                "p-invalid": formState.errors.item_price,
              })}
            />
          )}
        />
        {formState.errors.item_price && (
          <small id="item_price" className="p-error block pt-1">
            Masukkan harga barang
          </small>
        )}
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="cod_price">Nilai COD : </label>
        <Controller
          control={control}
          name="cod_price"
          defaultValue={0}
          render={({ field }) => (
            <InputNumber
              onBlur={field.onBlur}
              ref={field.ref}
              value={field.value}
              onValueChange={(e) => {
                field.onChange(e);
                let current_values = getValues();
                if (e.value > 0) {
                  reset({ ...current_values, item_price: 0, cod: true });
                } else {
                  reset({ ...current_values, cod_price: 0, cod: false });
                }
              }}
              mode="decimal"
              placeholder="Masukkan nilai cod"
              className={classNames({
                "p-invalid": formState.errors.cod_price,
              })}
            />
          )}
        />
        {formState.errors.cod_price && (
          <small id="cod_price" className="p-error block pt-1">
            Masukkan harga barang cod
          </small>
        )}
      </div>
      <div className="field col-12 md:col-12 mt-4">
        <div className="font-bold">
          <Controller
            control={control}
            defaultValue={false}
            name="insurance"
            render={({ field }) => (
              <Checkbox tooltip={!item_price_non_cod && !item_price_cod ? "Silahkan isi harga barang terlebih dahulu" : null} disabled={(!item_price_non_cod && !item_price_cod) || !estimate?.shipping_cost} className="mr-2" inputId="binary" checked={field.value} onChange={(e) => field.onChange(e)} />
            )}
          />
          <span>Asuransi (opsional)</span>
        </div>
      </div>
      <div className="field col-12 md:col-12 non-input">
        <label htmlFor="insurance_price">
          <span className="pr-1">Harga Asuransi :</span>
          {loadingInsurance ? <i className="pi pi-spin pi-spinner" /> : null}
        </label>
        <Controller control={control} defaultValue={0} name="insurance_price" render={({ field }) => <InputNumber disabled onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} mode="decimal" placeholder="0" />} />
      </div>
      <PriceEstimationForm data={estimate} />
    </div>
  );
};

export default ProductInformation;
